import { useEffect, useState } from "react";

import LoadingMask from "react-loadingmask";
import { SettingBannerDriverApp } from "./SettingBannerDriverApp"
import { SettingBannerUserApp } from "./SettingBannerUserApp";

export const SettingBanner = () => {
  const [indexTabSelected, setIndexTabSelected] = useState(0)
  const [loading, setloading] = useState(false)
  const [data, setData] = useState([])

  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <ul className="nav nav-pills" id="pills-tab">
              <li className="nav-item">
                <div
                  style={{ cursor: 'pointer' }}
                  className={`nav-link ${indexTabSelected === 0 ? 'active' : ''}`}
                  onClick={() => setIndexTabSelected(0)}
                >
                  User App
                </div>
              </li>
              <li className="nav-item">
                <div
                  style={{ cursor: 'pointer' }}
                  className={`nav-link ${indexTabSelected === 1 ? 'active' : ''}`}
                  onClick={() => setIndexTabSelected(1)}
                >
                  Driver App
                </div>
              </li>
            </ul>
            <div className="row">
              <div className="col-12">
                <LoadingMask loading={loading} text={"loading..."}>
                  {
                    indexTabSelected === 0 ? <SettingBannerUserApp /> : <SettingBannerDriverApp />
                  }
                </LoadingMask>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}