import "react-loadingmask/dist/react-loadingmask.css";

import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { api, header_token, img_url, rider_status_option } from "../../config";

import Axios from "axios";
import LoadingMask from "react-loadingmask";
import { RiderDetailHead } from "./RiderDetailHead";
import { RiderDetailHeader } from "./RiderDetailHeader"
import swal from "sweetalert";

export const RiderBankAccount = () => {
  const { id } = useParams();
  const [loading, setloading] = useState(false)
  const [bankAccounts, setBankAccount] = useState([])

  const getBankAccount = async () => {
    try {
      let { data } = await Axios.get(
        `${api}/admin/user/account/${id}`,
        header_token
      );

      setBankAccount(data)
    } catch (error) {
      console.log(error);
      swal(`ผิดพลาด: ${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  useEffect(() => {
    getBankAccount()
  }, [])

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">บัญชีธนาคาร</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                พนักงานส่งทั้งหมด
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <RiderDetailHead />
              <RiderDetailHeader />

              <LoadingMask loading={loading} text={"loading..."}>
                <div className="tab-content" id="myTabContent">
                  <div style={{ minHeight: 500 }}>
                    <table id="dataTable" className="table">
                      <thead>
                        <tr>
                          <th>ชื่อบัญชี</th>
                          <th>เลขบัญชี</th>
                          <th>ธนาคาร</th>
                          <th>สาขา</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bankAccounts.map((acc, index) => {
                          return (
                            <tr key={index}>
                              <td>{acc.name}</td>
                              <td>{acc.number}</td>
                              <td>{acc.bank.name_th}</td>
                              <td>{acc.branch_name}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}