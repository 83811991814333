import { NavLink, Route, Switch } from "react-router-dom";
import React, { useContext } from "react";

import { AffiliateActive } from "./AffiliateActive"
import { AffiliateAll } from "./AffiliateAll"
import { AffiliateNew } from "./AffiliateNew"
import { NotiContext } from "../../store/keep";

export const AffiliateHead = () => {
  const { noti } = useContext(NotiContext);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">Affiliate</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/affiliate"
              >
                ทั้งหมด
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/affiliate/new"
              >
                คำขอใหม่
                <span className="badge badge-pill badge-danger ml-1">
                  {noti.affiliate > 0 ? noti.affiliate : ""}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/affiliate/active"
              >
                กำลังใช้งาน
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <Switch>
        <Route exact path={`/affiliate`}>
          <AffiliateAll />
        </Route>
        <Route exact path={`/affiliate/new`}>
          <AffiliateNew />
        </Route>
        <Route exact path={`/affiliate/active`}>
          <AffiliateActive />
        </Route>
      </Switch>
    </div>
  )
}