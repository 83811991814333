import { api, header_token, img_url } from "../../../config";
import { useEffect, useRef, useState } from "react";

import Axios from "axios";
import LoadingMask from "react-loadingmask";
import Select from "react-select";
import Switch from "react-switch";
import swal from "sweetalert";
import { useForm } from "react-hook-form";

export const SettingAffiliate = () => {
  const [indexTabSelected, setIndexTabSelected] = useState(0)
  const [loading, setLoading] = useState(false)
  const [commisionRates, setCommissionRates] = useState([])
  const [typeSelected, setTypeSelected] = useState({ value: 'percent', label: '%' })
  const [rateSelected, setRateSelected] = useState(null)
  const [activeSelected, setActiveSelected] = useState(false)

  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      order_count: 0,
      commision: 0,
      total_order_revenue: 0,
    }
  });

  const {
    register: registerEdit,
    errors: errorsEdit,
    handleSubmit: handleSubmitEdit,
    reset: resetEdit,
    setValue: setValueEdit
  } = useForm({
    defaultValues: {
      order_count: 0,
      commision: 0,
      total_order_revenue: 0,
    }
  });

  const closeAddPass = useRef(null);
  const closeEditPass = useRef(null);

  const types = [
    { value: 'percent', label: '%' },
    { value: 'amount', label: 'บาท' },
  ]

  const onSubmit = async (value) => {
    try {
      const body = {
        order_count: parseInt(value.order_count),
        type: typeSelected.value,
        value: parseInt(value.commision),
        available: true,
        total_order_revenue: parseInt(value.total_order_revenue)
      }

      let res = await Axios.post(
        `${api}/admin/affiliate/rate`,
        body,
        header_token
      );

      if (res.status === 201) {
        getAffliateRate();
        swal("เพิ่มเสร็จสิ้น !!", {
          icon: "success",
        });
      }

      reset()
      closeAddPass.current.click();
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const onSubmitEdit = async (value) => {
    try {
      if (rateSelected !== null) {
        const body = {
          order_count: parseInt(value.order_count),
          type: typeSelected.value,
          value: parseInt(value.commision),
          available: activeSelected,
          total_order_revenue: parseInt(value.total_order_revenue),
        }

        let res = await Axios.patch(
          `${api}/admin/affiliate/rate/${rateSelected.id}`,
          body,
          header_token
        );

        if (res.status === 200) {
          getAffliateRate();
          swal("แก้ไขสำเร็จ !!", {
            icon: "success",
          });
          closeEditPass.current.click();
        }
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const onSubmitDelete = async (id) => {
    try {
      let res = await Axios.delete(
        `${api}/admin/affiliate/rate/${id}`,
        header_token
      );

      if (res.status === 200) {
        getAffliateRate();
        swal("ลบสำเร็จ !!", {
          icon: "success",
        });
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const getAffliateRate = async () => {
    try {
      const body = {}

      let { data: { affiliate_rate } } = await Axios.post(
        `${api}/affiliate/rate`,
        body,
        header_token
      );

      setCommissionRates(affiliate_rate)

    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  useEffect(() => {
    getAffliateRate()
  }, [])

  return (
    <>
      <div className="row mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-2">
                <button
                  data-toggle="modal"
                  data-target=".add-rate"
                  className="btn btn-outline-primary btn-sm ml-auto mr-2"
                  onClick={() => {

                  }}
                >
                  + เพิ่มคอมมิชชั่นเรท
                </button>
              </div>
              <div className="row">
                <div className="col-12">
                  <LoadingMask loading={loading} text={"loading..."}>
                    <table id="bernner" className="table ">
                      <thead>
                        <tr>
                          <th>รายการ / มูลค่ารวม</th>
                          <th>ค่าคอมมิชชั่น</th>
                          <th>สถานะ</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          commisionRates.length > 0 && commisionRates.map((rate, index) => {
                            return <tr key={index}>
                              <td>{`${rate.order_count ? rate.order_count.toLocaleString() : 0} / ${rate.total_order_revenue ? rate.total_order_revenue.toLocaleString() : 0} บาท`} ขึ้นไป</td>
                              <td>{rate.value}{rate.type === 'percent' ? '%' : ' บาท'}</td>
                              <td>
                                {
                                  rate.available ?
                                    <div className="badge badge-success">
                                      ใช้งาน
                                    </div>
                                    : <div className="badge badge-danger">
                                      ไม่ได้ใช้งาน
                                    </div>
                                }
                              </td>
                              <td align="right" width="300">
                                <button
                                  className="btn btn-outline-primary btn-sm ml-2"
                                  onClick={() => {
                                    swal({
                                      title: `ต้องการลบหรือไม่ ?`,
                                      confirmButtonText: "ลบ",
                                      cancelButtonText: "ยกเลิก",
                                      icon: "warning",
                                      buttons: true,
                                      dangerMode: true,
                                    }).then(async (willDelete) => {
                                      if (willDelete) {
                                        onSubmitDelete(rate.id);
                                      }
                                    });
                                  }}
                                >
                                  ลบ
                                </button>
                                <button
                                  data-toggle="modal"
                                  data-target={`.edit-rate`}
                                  className="btn btn-outline-primary btn-sm ml-2"
                                  onClick={() => {
                                    const type = types.find((t) => t.value === rate.type)
                                    setRateSelected(rate)
                                    if (type !== undefined)
                                      setTypeSelected(type)
                                    setValueEdit('order_count', rate.order_count)
                                    setValueEdit('total_order_revenue', rate.total_order_revenue)
                                    setValueEdit('commision', rate.value)
                                    setActiveSelected(rate.available)
                                  }}
                                >
                                  แก้ไข
                                </button>
                              </td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </LoadingMask>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal add-rate" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <span>เพิ่มคอมมิชชั่นเรท</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form key="create-banner" onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-2">
                  <div className="col-12 border-bottom mb-3">
                    <div className="form-group row">
                      <label
                        htmlFor="description"
                        className="col-sm-3 col-form-label"
                      >
                        จำนวนรายการ
                      </label>
                      <div className="col-sm-8" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <input
                          style={{ width: '100px' }}
                          id="order_count"
                          name="order_count"
                          type="number"
                          min={0}
                          ref={register({
                            required: true,
                          })}
                          className={`form-control ${errors.order_count
                            ? "error-input"
                            : ""
                            }`}
                          placeholder="จำนวนรายการ"
                        />
                        <div
                          style={{ width: '100px' }}
                        >
                          <label className="col-form-label">รายการขึ้นไป</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="description"
                        className="col-sm-3 col-form-label"
                      >
                        จำนวนเงินรวม
                      </label>
                      <div className="col-sm-8" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <input
                          style={{ width: '100px' }}
                          id="total_order_revenue"
                          name="total_order_revenue"
                          type="number"
                          min={0}
                          ref={register({
                            required: true,
                          })}
                          className={`form-control ${errors.total_order_revenue
                            ? "error-input"
                            : ""
                            }`}
                          placeholder="จำนวนเงินรวม"
                        />
                        <div
                          style={{ width: '100px' }}
                        >
                          <label className="col-form-label">บาทขึ้นไป</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="commision"
                        className="col-sm-3 col-form-label"
                      >
                        ค่าคอมมิชชั่น
                      </label>
                      <div className="col-sm-8" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <input
                          style={{ width: '100px' }}
                          id="commision"
                          name="commision"
                          type="number"
                          min={0}
                          ref={register({
                            required: true,
                          })}
                          className={`form-control ${errors.commision
                            ? "error-input"
                            : ""
                            }`}
                          placeholder="ค่าคอมมิชชั่น"
                        />
                        <div
                          style={{ width: '100px' }}
                        >
                          <Select
                            id="type"
                            name="type"
                            options={types}
                            placeholder={"สถานะ"}
                            value={typeSelected}
                            onChange={(e) => setTypeSelected(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mr-2 mb-4">
                  <div className="col-12">
                    <div className="row">
                      <button
                        data-dismiss="modal"
                        aria-label="Close"
                        className="btn btn-outline-primary mr-2 ml-auto "
                        ref={closeAddPass}
                        type="button"
                        onClick={() => {
                          reset()
                        }}
                      >
                        ยกเลิก
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary  mr-2 "
                      >
                        บันทึก
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal edit-rate" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <span>เพิ่มคอมมิชชั่นเรท</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form key="create-banner" onSubmit={handleSubmitEdit(onSubmitEdit)}>
                <div className="row mb-2">
                  <div className="col-12 border-bottom mb-3">
                    <div className="form-group row">
                      <label
                        htmlFor="description"
                        className="col-sm-3 col-form-label"
                      >
                        จำนวนรายการ
                      </label>
                      <div className="col-sm-8" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <input
                          style={{ width: '100px' }}
                          id="order_count"
                          name="order_count"
                          type="number"
                          min={0}
                          ref={registerEdit({
                            required: true,
                          })}
                          className={`form-control ${errors.order_count
                            ? "error-input"
                            : ""
                            }`}
                          placeholder="จำนวนรายการ"
                        />
                        <div
                          style={{ width: '100px' }}
                        >
                          <label className="col-form-label">รายการขึ้นไป</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="description"
                        className="col-sm-3 col-form-label"
                      >
                        จำนวนเงินรวม
                      </label>
                      <div className="col-sm-8" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <input
                          style={{ width: '100px' }}
                          id="total_order_revenue"
                          name="total_order_revenue"
                          type="number"
                          min={0}
                          ref={registerEdit({
                            required: true,
                          })}
                          className={`form-control ${errors.total_order_revenue
                            ? "error-input"
                            : ""
                            }`}
                          placeholder="จำนวนเงินรวม"
                        />
                        <div
                          style={{ width: '100px' }}
                        >
                          <label className="col-form-label">บาทขึ้นไป</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="commision"
                        className="col-sm-3 col-form-label"
                      >
                        ค่าคอมมิชชั่น
                      </label>
                      <div className="col-sm-8" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <input
                          style={{ width: '100px' }}
                          id="commision"
                          name="commision"
                          type="number"
                          min={0}
                          ref={registerEdit({
                            required: true,
                          })}
                          className={`form-control ${errors.commision
                            ? "error-input"
                            : ""
                            }`}
                          placeholder="ค่าคอมมิชชั่น"
                        />
                        <div
                          style={{ width: '100px' }}
                        >
                          <Select
                            id="type"
                            name="type"
                            options={types}
                            placeholder={"สถานะ"}
                            value={typeSelected}
                            onChange={(e) => setTypeSelected(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="commision"
                        className="col-sm-3 col-form-label"
                      >
                        สถานะ
                      </label>
                      <div style={{ height: '10px', width: '10px' }}>
                        <Switch
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onChange={e => setActiveSelected(e)}
                          checked={activeSelected}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mr-2 mb-4">
                  <div className="col-12">
                    <div className="row">
                      <button
                        data-dismiss="modal"
                        aria-label="Close"
                        className="btn btn-outline-primary mr-2 ml-auto "
                        ref={closeEditPass}
                        type="button"
                        onClick={() => {
                          resetEdit()
                        }}
                      >
                        ยกเลิก
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary  mr-2 "
                      >
                        บันทึก
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}