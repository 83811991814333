import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { api, header_token, img_url, rider_status_option } from "../../config";

import Axios from "axios";
import LoadingMask from "react-loadingmask";
import ReactPaginate from "react-paginate";
import { UserDetailHeader } from "./UserDetailHeader"
import { getMethodText } from "../../utils/Function"

export const UserDetailTransaction = () => {
  const { id } = useParams();
  const limit = 10

  const [isLoading, setIsLoading] = useState(false)
  const [transactions, setTransactions] = useState([])
  const [transactionsRaw, setTransactionsRaw] = useState([])
  const [balanceLogs, setBalanceLogs] = useState([])
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [total_count, settotal_count] = useState(0);

  const getTranasctions = async () => {
    setIsLoading(true)
    try {
      let { data: { transactions, meta } } = await Axios.get(
        `${api}/transaction/history/${id}?sort_by=created_at&sort=-1&page=1&limit=99999999999`,
        // { status: 1 },
        header_token
      );
      const _balanceLogs = []
      let credit = 0, wallet = 0
      // if (transactions !== undefined) {
      //   const fork = [...transactions]
      //   fork.reverse().map((f) => {
      //     const amountValue = f.is_money_in ? f.amount : -(f.amount)
      //     if (f.is_credit) credit += amountValue
      //     if (f.is_credit_bonus) wallet += amountValue

      //     _balanceLogs.push({
      //       amountCredit: credit.toLocaleString(),
      //       amountWallet: wallet.toLocaleString()
      //     })
      //   })
      // }

      // setBalanceLogs(_balanceLogs.reverse())
      setTransactionsRaw(transactions ?? [])
      if (transactions !== null) {
        let cal_page_count = Math.ceil(transactions.length / limit);
        const _data = transactions.slice(0, transactions.length >= limit ? limit : transactions.length)
        setTransactions(_data)
        setpage_count(cal_page_count);
        settotal_count(transactions.length)
      }
    } catch (error) {
      console.log('getTranasctions error: ', error)
    }
    setIsLoading(false)
  }

  const getNewPage = () => {
    if (transactionsRaw.length > 0) {
      const balanceTransaction = total_count - (current_page === 1 ? 0 : (current_page - 1) * limit)
      const _data = transactionsRaw.slice((current_page === 1 ? 0 : (current_page - 1) * limit), balanceTransaction >= limit ? current_page * limit : transactionsRaw.length)
      setTransactions(_data)
    }
  }

  useEffect(() => {
    getTranasctions()
  }, [])

  useEffect(() => {
    getNewPage();
  }, [current_page]);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">รายละเอียด</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <UserDetailHeader />
              <LoadingMask loading={isLoading} text={"loading..."}>
                <div className="tab-content" id="myTabContent">
                  <div className="row">
                    <div className="col">
                      <h4 className="mt-3">ทั้งหมด {total_count} รายการ</h4>
                    </div>
                  </div>
                  <div style={{ minHeight: 500 }}>
                    <table id="dataTable" className="table">
                      <thead>
                        <tr>
                          <th>วันที่</th>
                          <th>รายการ</th>
                          <th>ช่องทาง/ออเดอร์</th>
                          <th>กระเป๋า</th>
                          <th style={{ textAlign: 'right' }}>จำนวน</th>
                          {/* <th style={{ textAlign: 'right' }}>คงเหลือ(เครดิต)</th>
                          <th style={{ textAlign: 'right' }}>คงเหลือ(วอลเล็ต)</th> */}
                          <th style={{ textAlign: 'right' }}>โดย</th>
                          <th>หมายเหตุ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((transaction, index) => {
                          // type 1 = เติมเงิน, 2 = ถอนเงิน, 3 = วิ่งงาน
                          let typeStr = 'เครดิตเงินคืน'
                          if (transaction.type === 2) {
                            typeStr = 'ยอดหักค่าขนส่ง'
                          } else if (transaction.type === 1) {
                            typeStr = 'ยอดเติมเครดิต'
                          } else if (transaction.type === 0) {
                            typeStr = `เครดิตเงินคืน (${transaction.code})`
                          }

                          const isMoneyIn = (transaction.is_money_in || transaction.is_pay_solution)
                          const textColor = isMoneyIn ? 'green' : 'red'
                          const amount = `${isMoneyIn ? '+' : '-'}${transaction.amount.toLocaleString()}`
                          const walletType = transaction.is_credit ? 'เครดิต' : 'วอลเล็ต'
                          const method = getMethodText(transaction)

                          return (
                            <tr key={index}>
                              <td>{transaction.created_at}</td>
                              <td>{typeStr}</td>
                              <td>{method}</td>
                              <td>{walletType}</td>
                              <td>
                                <div style={{ color: textColor, textAlign: 'right' }}>{amount}</div>
                              </td>
                              {/* <td style={{ textAlign: 'right' }} >{balanceLogs !== undefined && balanceLogs.length > 0 ? balanceLogs[index].amountCredit : 0}</td>
                              <td style={{ textAlign: 'right' }}>{balanceLogs !== undefined && balanceLogs.length > 0 ? balanceLogs[index].amountWallet : 0}</td> */}
                              <td>{transaction.created_by !== null ? transaction.created_by.name : ''}</td>
                              <td>{transaction.remark}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </LoadingMask>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <div></div>
                <div className="mt-2" >
                  <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    breakLabel={<span className="gap">...</span>}
                    pageCount={page_count}
                    onPageChange={(e) => setcurrent_page(e.selected + 1)}
                    forcePage={current_page - 1}
                    containerClassName={"pagination"}
                    previousLinkClassName={"previous_page"}
                    nextLinkClassName={"next_page"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="mt-2 " style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <span style={{ fontSize: '14px', marginRight: '10px' }}>ค้นหา:</span>
                  <input
                    autoComplete="off"
                    type="number"
                    className="form-control- global_filter"
                    placeholder="1"
                    style={{ width: '80px', paddingLeft: '5px', paddingRight: '5px' }}
                    onChange={(e) => {
                      setcurrent_page(e.target.value === '' ? 1 : e.target.value)
                    }}
                    defaultValue={1}
                    min={1}
                    max={page_count}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}