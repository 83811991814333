import { Link } from "react-router-dom";
import LoadingMask from "react-loadingmask";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { convertAffiliateStatusToText } from './../../utils/Function'
import moment from "moment";
import { useState } from "react";
const limitOptions = [
  { label: "50", value: 50, },
  { label: "100", value: 100, },
  { label: "150", value: 150, },
]

export const AffiliateTable = (props) => {
  const {
    data,
    loading,
    onCancel = null,
    onConfirm = null,
    onClickDetail = null,
    setLimit = () => { },
    limit = { label: "50", value: 50 },
    page_count = 0,
    setcurrent_page = () => { },
    current_page = 1,
  } = props

  const [image, setImage] = useState(null)

  return <>
    <LoadingMask loading={loading} text={"loading..."}>
      <div style={{ minHeight: 500 }}>
        <div className="table-responsive">
          <table id="dataTable" className="table ">
            <thead>
              <tr>
                <th style={{ minWidth: 200 }}>วันที่ยื่นขอ</th>
                <th style={{ textAlign: 'center' }}>ID</th>
                <th style={{ minWidth: 350, textAlign: 'center' }}>ผู้ใช้งาน</th>
                <th style={{ minWidth: 200 }}>บัตรประชาชน</th>
                <th style={{ minWidth: 150 }}>รหัสแนะนำ</th>
                <th style={{ minWidth: 150, textAlign: 'center' }}>แนะนำทั้งหมด</th>
                <th style={{ minWidth: 150, textAlign: 'center' }}>จำนวนออเดอร์</th>
                <th style={{ minWidth: 150, textAlign: 'center' }}>คอมมิชชั่น</th>
                <th style={{ minWidth: 150 }}>สถานะ</th>
                <th style={{ minWidth: 200 }}>หมายเหตุ</th>
                <th style={{ minWidth: 150 }}></th>
              </tr>
            </thead>
            <tbody>
              {
                data.map((value, index) => {
                  return <tr
                    key={`affiliate-${index}`}
                  >
                    <td>{moment(value.created_at).format('DD/MM/YYYY HH:mm')}</td>
                    <td>{value.user.user_id}</td>
                    <td style={{ textAlign: 'center' }}>
                      <div>{value.user.full_name}</div>
                      <div>{value.user.phone_number}</div>
                    </td>
                    <td>
                      <div
                        className="thumbnail cursor-img"
                        data-toggle="modal"
                        data-target=".show-img"
                        onClick={() => {
                          setImage(value.user.identification_picture);
                        }}
                      >
                        <img
                          className="lazy img-responsive"
                          src={
                            value.user.identification_picture === ""
                              ? "/images/no-img.jpg"
                              : value.user.identification_picture
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/no-img.jpg";
                          }}
                        />
                      </div>
                    </td>
                    <td >{value.code ?? "-"}</td>
                    <td style={{ textAlign: 'center' }}>{value.user_id ? value.user_id.length ?? "-" : '-'}</td>
                    <td style={{ textAlign: 'center' }}>{value.order_count.toLocaleString()}</td>
                    <td style={{ textAlign: 'center' }}>{value.summary_total.toLocaleString()}</td>
                    <td>
                      {(() => {
                        const text = convertAffiliateStatusToText(value.status)
                        switch (value.status) {
                          case 0:
                            return (
                              <div className="badge badge-danger">
                                {text}
                              </div>
                            );
                          case 1:
                            return (
                              <div className="badge badge-primary">
                                {text}
                              </div>
                            );

                          case 2:
                            return (
                              <div className="badge badge-success">
                                {text}
                              </div>
                            );

                          default:
                            break;
                        }
                      })()}
                    </td>
                    <td>{value.remark}</td>
                    <td>
                      {
                        onConfirm !== null && <button
                          className="btn btn-outline-primary mb-1 mr-1"
                          onClick={() => onConfirm(value.id)}
                        >
                          อนุมัติ
                        </button>
                      }
                      {
                        onCancel !== null && <button
                          className="btn btn-outline-primary mb-1"
                          onClick={() => onCancel(value.id)}
                        >
                          ยกเลิก
                        </button>
                      }
                      {
                        onClickDetail !== null && <Link target="_blank"
                          to={value.user.role.name_en === 'rider' ? `/rider/affiliate/${value.user._id}` : `/user/affiliate/${value.user._id}`}
                        >
                          <button
                            className="btn btn-outline-primary mb-1"
                          >
                            รายละเอียด
                          </button>
                        </Link>
                      }
                    </td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </LoadingMask>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <div></div>
      <div className="mt-2" >
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          breakLabel={<span className="gap">...</span>}
          pageCount={page_count}
          onPageChange={(e) => setcurrent_page(e.selected + 1)}
          forcePage={current_page - 1}
          containerClassName={"pagination"}
          previousLinkClassName={"previous_page"}
          nextLinkClassName={"next_page"}
          disabledClassName={"disabled"}
          activeClassName={"active"}
        />
      </div>
      <div className="mt-2 " style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '5px' }}>
          <span style={{ fontSize: '14px', marginRight: '10px' }}>ค้นหา:</span>
          <input
            autoComplete="off"
            type="number"
            className="form-control- global_filter"
            placeholder="1"
            style={{ width: '80px', paddingLeft: '5px', paddingRight: '5px' }}
            onChange={(e) => {
              setcurrent_page(e.target.value)
            }}
            defaultValue={1}
            min={1}
            max={page_count}
          />
        </div>
        <div style={{ minWidth: '100px' }}>
          <Select
            name="per_page"
            options={limitOptions}
            defaultValue={limit}
            onChange={async (e) => {
              setLimit(e)
            }}
            menuPlacement={'top'}
          />
        </div>
      </div>
    </div>

    <div id="showModdal" className="modal  show-img" role="dialog">
      <div className="modal-dialog ">
        <div className="modal-content" style={{ width: 800 }}>
          <div className="modal-header">
            <span>รูปภาพ</span>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body">
            <img
              alt=""
              src={image ? image : ""}
              style={{ maxWidth: 600, display: "block", margin: "auto" }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/no-img.jpg";
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </>
}