import "react-loadingmask/dist/react-loadingmask.css";
import "react-day-picker/lib/style.css";
import "moment/locale/th";

import React, { useRef, useState } from "react";
import { api, header_token } from "../../config";

import Axios from "axios";
import swal from "sweetalert";

export const ReportPaySolution = () => {
  const [xlsxFile, setXlsxFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef(null);

  const handleImageUploadClick = () => {
    fileInputRef.current?.click();
  };

  const uploadAction = async () => {
    try {
      setLoading(true)
      let link = ''
      let body = new FormData();
      body.append("file", xlsxFile);

      let { data: { fileUrl }, status } = await Axios.post(
        `${api}/admin/paysolutions/import`,
        body,
        header_token
      );

      if (status === 200) {
        if (fileUrl !== null && fileUrl !== undefined)
          link = fileUrl

        if (link !== '') {
          window.open(link, "_blank")
        } else {
          swal("ไม่สามารถโหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง", {
            icon: "warning",
          });
        }
        setXlsxFile(null)
      }

      setLoading(false)
    } catch (error) {
      console.log('uploadAction error: ', error)
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      setLoading(false)
    }
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      setXlsxFile(file);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div
              className="card-body"
              style={{
                minHeight: 500,
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <form encType="multipart/form-data">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ marginBottom: '10px' }} className="tx-13 text-muted">กรุณาอัพโหลดไฟล์ .xlsx ที่ได้จาก Pay Solution</span>
                  <div>
                    <div
                      className={'dropZone'}
                      onDragOver={handleDragOver}
                      onDrop={handleFileDrop}
                      onClick={handleImageUploadClick}
                      style={{ height: '200px' }}
                    >
                      {xlsxFile ? (
                        <span>{xlsxFile.name}</span>
                      ) : (
                        <span>เลือกไฟล์หรือลากไฟล์ลงที่นี้</span>
                      )}
                      <input
                        ref={fileInputRef}
                        type="file"
                        style={{ display: "none" }}
                        accept=".xlsx"
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            const validateIndex = e.target.files[0].type.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                            if (validateIndex === -1) {
                              swal(`สามารถนำเข้าไฟล์ได้แค่นามสกุล .xlsx เท่านั้น`, {
                                icon: "error",
                                button: false,
                              });
                              e.target.value = null;
                            } else {
                              const file = e.target.files[0];
                              setXlsxFile(file);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <button
                  type="button"
                  className="btn btn-primary mr-2 ml-2"
                  style={{ marginTop: -2, padding: `5px 10px 5px 10px`, width: '150px' }}
                  onClick={() => uploadAction()}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                  {` `}Export
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}