import "react-loadingmask/dist/react-loadingmask.css";

import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import React, { useEffect, useState } from "react";
import { api, header_token } from "../../config";

import Axios from "axios";
import LoadingMask from "react-loadingmask";
import { SettingAreaForm } from "./SettingAreaForm";
import swal from "sweetalert";

export const SettingAreaTable = (props) => {
  const {
    data = [],
    loading = false,
    deleteArea = () => { },
    editArea = () => { }
  } = props

  const [tempData, setTempData] = useState([])
  const [tempDataSelected, setTempDataSelected] = useState(null)
  const [notifyUpdate, setNotifyUpdate] = useState(0)

  const mapShowMoreData = (listArea) => {
    try {
      const temp = []
      for (let index = 0; index < listArea.length; index++) {
        const area = listArea[index];
        temp.push({
          ...area,
          showMore: false,
        })
      }
      setTempData(temp)
    } catch (error) {
      console.log('mapShowMoreData: ', error)
    }
  }

  const onSubmitEdit = async (value) => {
    try {
      swal({
        title: `ต้องการแก้ไข  ${tempDataSelected.name_th} ?`,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willConfirm) => {
        if (willConfirm) {
          let is_edit = editArea(tempDataSelected, value)

          if (is_edit) {
            swal("แก้ไขเสร็จสิ้น !!", {
              icon: "success",
            });
          } else {
            swal("เกิดข้อผิดพลาด !!", {
              icon: "error",
            });
          }
        }
      });
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  useEffect(() => {
    mapShowMoreData(data)
  }, [data])

  return <>
    <LoadingMask loading={loading} text={"loading..."}>
      <div style={{ minHeight: 500 }}>
        <table id="example" className="table ">
          <thead>
            <tr>
              <th>พื้นที่บริการ</th>
              <th width="80">Edit</th>
              <th width="80">Delete</th>
            </tr>
          </thead>
          <tbody>
            {tempData.map((value, index) => {
              return (
                <tr key={value.id}>
                  <td
                    className="noselect"
                    onClick={() => {
                      value.showMore = !value.showMore
                      setNotifyUpdate(notifyUpdate + 1)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {
                      value.showMore ? <IoMdArrowDropdown style={{ marginRight: '10px' }} /> : <IoMdArrowDropright style={{ marginRight: '10px' }} />
                    }
                    {value.name_th} ({value.provinces !== null && value.provinces !== undefined ? value.provinces.length : 0} จังหวัด)
                    <div
                      hidden={value.showMore ? undefined : true}
                      style={{ paddingRight: '25px', paddingLeft: '25px' }}
                    >
                      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        {
                          value.provinces !== null && value.provinces.length > 0 && value.provinces.map((_province, index) => {
                            const disCount = _province.districts !== null ? _province.districts.length > 0 ? `(${_province.districts.length} อำเภอ)` : '' : ''
                            return <div key={`province-${index}`}>
                              <div>
                                {`${index + 1}. ${_province.name_th} ${disCount}`} {`${_province.districts === null || _province.districts.length === 0 ? '(ทุกอำเภอ / ทุกเขต)' : ''}`}
                              </div>
                              {
                                _province.districts !== null && _province.districts.length > 0 && _province.districts.map((dis, i) => {
                                  const showDis = i % 2 === 0

                                  if (showDis) {
                                    let nextDis = null
                                    if (_province.districts[i + 1] !== undefined && _province.districts[i + 1] !== null) {
                                      nextDis = _province.districts[i + 1]
                                    }

                                    const subCount = dis.sub_districts !== null && dis.sub_districts.length > 0 ? `(${dis.sub_districts.length} ตำบล)` : ''
                                    const subNextCount = nextDis !== null ? nextDis.sub_districts !== null && nextDis.sub_districts.length > 0 ? `(${nextDis.sub_districts.length} ตำบล)` : '' : ''

                                    return <div key={`districts-${i}`} style={{ display: 'flex', flexDirection: 'row' }}>
                                      <div style={{ marginLeft: '15px', flex: 1, flexDirection: 'column' }}>
                                        <div>
                                          {`${index + 1}.${i + 1}. ${dis.name_th} ${subCount}`}
                                        </div>
                                        {
                                          dis.sub_districts !== null && dis.sub_districts.length > 0 && dis.sub_districts.map((_subdis, subIndex) => {
                                            const show = subIndex % 2 === 0

                                            if (show) {
                                              let nextSubDis = null
                                              if (dis.sub_districts[subIndex + 1] !== undefined && dis.sub_districts[subIndex + 1] !== null)
                                                nextSubDis = dis.sub_districts[subIndex + 1]

                                              return <div key={`sub-${_subdis.id}`} style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div key={`sub-districts-${subIndex}`} style={{ marginLeft: '15px', flex: 1 }}>
                                                  {`- ${_subdis.name_th}`}
                                                </div>
                                                {
                                                  nextSubDis !== null && <div key={`sub-districts-${subIndex + 1}`} style={{ marginLeft: '15px', flex: 1 }}>
                                                    {`- ${nextSubDis.name_th}`}
                                                  </div>
                                                }
                                              </div>
                                            }

                                            return null
                                          })
                                        }
                                      </div>
                                      {
                                        nextDis !== null && <div key={`districts-${i + 1}`} style={{ marginLeft: '15px', flex: 1, flexDirection: 'column' }}>
                                          <div>
                                            {`${index + 1}.${i + 2}. ${nextDis.name_th} ${subNextCount}`}
                                          </div>
                                          {
                                            nextDis.sub_districts !== null && nextDis.sub_districts.length > 0 && nextDis.sub_districts.map((_subdis, subIndex) => {
                                              const show = subIndex % 2 === 0

                                              if (show) {
                                                let nextSubDis = null
                                                if (nextDis.sub_districts[subIndex + 1] !== undefined && nextDis.sub_districts[subIndex + 1] !== null)
                                                  nextSubDis = nextDis.sub_districts[subIndex + 1]

                                                return <div key={`sub-${_subdis.id}`} style={{ display: 'flex', flexDirection: 'row' }}>
                                                  <div key={`sub-districts-${subIndex}`} style={{ marginLeft: '15px', flex: 1 }}>
                                                    {`- ${_subdis.name_th}`}
                                                  </div>
                                                  {
                                                    nextSubDis !== null && <div key={`sub-districts-${subIndex + 1}`} style={{ marginLeft: '15px', flex: 1 }}>
                                                      {`- ${nextSubDis.name_th}`}
                                                    </div>
                                                  }
                                                </div>
                                              }

                                              return null
                                            })
                                          }
                                        </div>
                                      }
                                    </div>
                                  }
                                  return null
                                })
                              }
                            </div>
                          })
                        }
                      </div>
                    </div>
                  </td>
                  <td className="edit">
                    <i
                      style={{ cursor: "pointer" }}
                      className="fa tx-20 mt-1 ml-1 text-muted fa-edit"
                      data-toggle="modal"
                      data-target=".edit-area"
                      onClick={() => {
                        setTempDataSelected(value)
                      }}
                    ></i>
                  </td>
                  <td className="trash">
                    <i
                      style={{ cursor: "pointer" }}
                      className="fa fa-trash-o tx-20 mt-1 ml-3 text-muted"
                      onClick={() => {
                        swal({
                          title: `ต้องการลบ  ${value.name_th} ?`,
                          confirmButtonText: "ลบ",
                          cancelButtonText: "ยกเลิก",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then(async (willDelete) => {
                          if (willDelete) {
                            let is_delete = deleteArea(
                              value.id
                            );

                            if (is_delete) {
                              swal("ลบเสร็จสิ้น !!", {
                                icon: "success",
                              });
                            } else {
                              swal("เกิดข้อผิดพลาด !!", {
                                icon: "error",
                              });
                            }
                          }
                        });
                      }}
                    ></i>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </LoadingMask>
    <div className={`modal edit-area`} role="dialog" data-backdrop="static" data-keyboard="false">
      <SettingAreaForm
        onSubmit={onSubmitEdit}
        title='แก้ไขพื้นที่ให้บริการ'
        initValue={tempDataSelected}
      />
    </div>
  </>
}