import { NavLink, Route, Switch } from "react-router-dom";
import React, { useContext } from "react";

import { NotiContext } from "../../store/keep";
import { RefundHistory } from "./RefundHistory";
import { RefundNew } from "./RefundNew";
import { RefundOnProcessing } from "./RefundOnProcessing";

export const RefundHead = () => {
  const { noti } = useContext(NotiContext);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">ขอคืนเงิน</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">ขอคืนเงิน</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/refund"
              >
                คำขอใหม่
                <span className="badge badge-pill badge-danger ml-1">
                  {noti.refund > 0 ? noti.refund : ""}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/refund/processing"
              >
                กำลังดำเนินการ
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/refund/history"
              >
                ประวัติ
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <Switch>
        <Route exact path={`/refund`}>
          <RefundNew />
        </Route>
        <Route exact path={`/refund/processing`}>
          <RefundOnProcessing />
        </Route>
        <Route exact path={`/refund/history`}>
          <RefundHistory />
        </Route>
      </Switch>
    </div>
  );
};
