import "moment/locale/th";
import "react-loadingmask/dist/react-loadingmask.css";

import LoadingMask from "react-loadingmask";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { checkImageFlowOld } from '../../../utils/Function'
import { img_url } from "../../../config";
import moment from "moment";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { useState } from "react";

export const SettingBannerTable = (props) => {
  const {
    data = [],
    loading = false,
    closeEditPass = null,
    onSubmitEdit = () => { },
    onSubmitDelete = () => { },
    page_count = 0,
    setcurrent_page = () => { },
    current_page = 0,
  } = props
  const showBannerFor = [
    { value: 'user', label: "User App" },
    { value: 'rider', label: "Driver App" },
  ]

  const selectStatus = [
    { value: 0, label: "ไม่ได้ใช้งาน" },
    { value: 1, label: "ใช้งาน" },
  ]

  const [image, setImage] = useState('')
  const [banner, setBanner] = useState({ preview: "", base64: "" });
  const [showFor, setShowFor] = useState({ value: 'user', label: "User App" });
  const [status, setStatus] = useState({ value: 1, label: "ใช้งาน" });
  const [bannerSelected, setBannerSelected] = useState(null)

  const {
    register,
    errors,
    handleSubmit,
    reset,
    setValue
  } = useForm();

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return <>
    <LoadingMask loading={loading} text={"loading..."}>
      <div style={{ minHeight: 500 }}>
        <table id="bernner" className="table ">
          <thead>
            <tr>
              <th>วันที่สร้าง</th>
              <th>รูปภาพ</th>
              <th>คำอธิบาย</th>
              <th width="100">สถานะ</th>
              <th align="right" width="300"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => {
              return <tr key={index}>
                <td>
                  {moment(value.created_at).format("DD/MM/YYYY")}
                </td>
                <td>
                  <div
                    className="thumbnail cursor-img"
                    data-toggle="modal"
                    data-target=".show-img"
                    onClick={() => {
                      setImage(value.image);
                    }}
                  >
                    <img
                      className="lazy img-responsive"
                      src={
                        value.image === ""
                          ? "/images/no-img.jpg"
                          : value.image
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/images/no-img.jpg";
                      }}
                    />
                  </div>
                </td>
                <td>{value.description}</td>
                <td>
                  {
                    value.available ?
                      <div className="badge badge-success">
                        ใช้งาน
                      </div>
                      : <div className="badge badge-danger">
                        ไม่ได้ใช้งาน
                      </div>
                  }
                </td>
                <td align="right">
                  <button
                    className="btn btn-outline-primary btn-sm ml-auto"
                    onClick={() => {
                      swal({
                        title: `ต้องการลบหรือไม่ ?`,
                        confirmButtonText: "ลบ",
                        cancelButtonText: "ยกเลิก",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      }).then(async (willDelete) => {
                        if (willDelete) {
                          onSubmitDelete(value.id);
                        }
                      });
                    }}
                  >
                    ลบ
                  </button>
                  <button
                    data-toggle="modal"
                    data-target={`.edit-banner`}
                    className="btn btn-outline-primary btn-sm ml-2"
                    onClick={async () => {
                      const initSelectedType = value.type === 'user'
                        ? { value: 'user', label: "User App" }
                        : { value: 'rider', label: "Driver App" }

                      const initSelectedStatus = value.available
                        ? { value: 1, label: "ใช้งาน" }
                        : { value: 0, label: "ไม่ได้ใช้งาน" }

                      setBannerSelected(value)
                      setValue('description', value.description)
                      setShowFor(initSelectedType)
                      setStatus(initSelectedStatus)
                      setBanner({ preview: value.image, base64: "" })
                    }}
                  >
                    แก้ไข
                  </button>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </LoadingMask>
    <div className="mt-2" style={{ float: "right" }}>
      <ReactPaginate
        previousLabel={"← Previous"}
        nextLabel={"Next →"}
        breakLabel={<span className="gap">...</span>}
        pageCount={page_count}
        onPageChange={(e) => setcurrent_page(e.selected + 1)}
        forcePage={current_page - 1}
        containerClassName={"pagination"}
        previousLinkClassName={"previous_page"}
        nextLinkClassName={"next_page"}
        disabledClassName={"disabled"}
        activeClassName={"active"}
      />
    </div>
    <div className="modal edit-banner" role="dialog">
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <span>แก้ไข Banner</span>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body">
            <form key="create-banner" onSubmit={handleSubmit((val) => {
              const data = {
                description: val.description,
                image: banner.base64 === "" ? banner.preview : banner.base64,
                type: showFor.value,
                available: status.value === 1,
                id: bannerSelected.id,
              }
              onSubmitEdit(data)
            })}>
              <div className="row mb-2">
                <div className="col-12 border-bottom mb-3">
                  <div className="form-group row">
                    <label
                      htmlFor="description"
                      className="col-sm-3 col-form-label"
                    >
                      คำอธิบาย
                    </label>
                    <div className="col-sm-8">
                      <input
                        id="description"
                        name="description"
                        type="text"
                        ref={register({
                          required: true,
                        })}
                        className={`form-control ${errors.description
                          ? "error-input"
                          : ""
                          }`}
                        placeholder="คำอธิบาย"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="for_show">แสดงสำหรับ</label>
                    </div>
                    <div className="col-lg-8">
                      <Select
                        id="for_show"
                        name="for_show"
                        options={showBannerFor}
                        placeholder={"แสดงสำหรับ"}
                        value={showFor}
                        onChange={(e) => setShowFor(e)}
                        isDisabled={true}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="status">สถานะ</label>
                    </div>
                    <div className="col-lg-8">
                      <Select
                        id="status"
                        name="status"
                        options={selectStatus}
                        placeholder={"สถานะ"}
                        value={status}
                        onChange={(e) => setStatus(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">รูปที่แสดง</label>
                    </div>
                    <div className="col-sm-8">
                      <img
                        id="img-upload"
                        alt=""
                        src={
                          banner.base64 === ""
                            ? banner.preview === ""
                              ? ""
                              : checkImageFlowOld(banner.preview) ? img_url + banner.preview : banner.preview
                            : banner.preview
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = "/images/no-img.jpg";
                        }}
                        style={{ maxWidth: "100%", maxHeight: '540px' }}
                      />
                      <div className="row">
                        <div className="col-10">
                          <div className="input-group">
                            <input
                              type="file"
                              id="logo"
                              className={`form-control ${errors.logo ? "error-input" : ""}`}
                              accept=".png, .jpg, .jpeg"
                              onChange={async (e) => {
                                if (e.target.files.length) {
                                  await setBanner({
                                    preview: URL.createObjectURL(e.target.files[0]),
                                    base64: await toBase64(e.target.files[0]),
                                  });
                                }
                              }}
                              ref={(e) => {
                                register(
                                  { name: "logo" },
                                  {
                                    validate: (value) => {
                                      return banner.base64 || banner.preview
                                        ? true
                                        : false;
                                    },
                                  }
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <button
                            className="btn btn-light btn-sm mt-1"
                            onClick={() => {
                              setBanner({ preview: "", base64: "" });
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mr-2 mb-4">
                <div className="col-12">
                  <div className="row">
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-outline-primary mr-2 ml-auto "
                      ref={closeEditPass}
                      type="button"
                      onClick={() => {
                        reset()
                      }}
                    >
                      ยกเลิก
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary  mr-2 "
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div className="modal  show-img" role="dialog">
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <span>รูปภาพ</span>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body">
            <img
              className="lazy img-responsive"
              src={image !== '' ? image : "/images/no-img.jpg"}
              style={{ maxWidth: 600, display: "block", margin: "auto" }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/no-img.jpg";
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </>
}