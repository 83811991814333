import React, { useEffect, useState } from "react";
import { api, header_token } from "../../config";

import Axios from "axios";
import { SettingAreaForm } from "./SettingAreaForm";
import { SettingAreaTable } from './SettingAreaTable'
import swal from "sweetalert";

export const SettingArea = () => {
  const [loading, setloading] = useState(false);

  const [area_data, setarea_data] = useState([
    {
      id: "",
      name_th: "",
      name_en: "",
      edit: false,
      new: false,
      provinces: []
    },
  ]);

  const getData = async () => {
    setloading(true);
    try {
      let { data } = await Axios.get(`${api}/area`);

      setarea_data(data);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const deleteArea = async (area_id) => {
    try {
      let res = await Axios.delete(`${api}/area/${area_id}`, header_token);

      if (res.status === 200) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const editArea = async (tempDataSelected, value) => {
    try {
      let res = await Axios.put(
        `${api}/area/${tempDataSelected.id}`,
        value,
        header_token
      );
      if (res.status === 200) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }


  const onSubmitCreateArea = async (value) => {
    try {
      let res = await Axios.post(
        `${api}/area`,
        value,
        header_token
      );

      if (res.status === 201) {
        getData();
        swal("เพิ่มพื้นที่ให้บริการสำเร็จ !!", {
          icon: "success",
        });
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-12">
                <div className="col">
                  <h4 className="mb-4 mt-2">
                    ทั้งหมด {area_data.length} รายการ
                  </h4>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-2">
                      <button
                        data-toggle="modal"
                        data-target=".add-area"
                        className="btn btn-outline-primary btn-sm ml-auto mr-2"
                        onClick={() => {
                        }}
                      >
                        + เพิ่มพื้นที่
                      </button>
                    </div>
                    <div className="table-responsive">
                      <div className="content">
                        <SettingAreaTable
                          data={area_data}
                          loading={loading}
                          deleteArea={deleteArea}
                          editArea={editArea}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="showModdal" className={`modal add-area`} role="dialog" data-backdrop="static" data-keyboard="false">
        <SettingAreaForm
          onSubmit={onSubmitCreateArea}
          title='เพิ่มพื้นที่ให้บริการ'
        />
      </div>
    </div>
  );
};
