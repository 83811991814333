import { NavLink, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { api, header_token } from "../../config";

import Axios from "axios";
import { getRoleNameText } from "../../utils/Function"

export const UserDetailHeader = (
  { isApproved = true, }
) => {
  const { id } = useParams();
  const [data, setData] = useState(null)
  const [userType, setUserType] = useState('')

  const getUserDetail = async () => {
    try {
      let { data } = await Axios.get(
        `${api}/admin/user/profile/${id}`,
        header_token
      );
      if (data !== null && data !== undefined) {
        const userTypeText = getRoleNameText(data.role.name_en)
        let roleText = userTypeText
        if (data.is_company) {
          roleText = userTypeText + `${data.user_business_type !== '' ? `(${data.user_business_type})` : ''}`
        }

        let images = []

        if (data.business_registration !== '') {
          images.push(data.business_registration)
        }

        if (data.vat_certificate !== '') {
          images.push(data.vat_certificate)
        }

        if (data.authorized_signatory_id !== '') {
          images.push(data.authorized_signatory_id)
        }

        if (data.book_bank_image !== '') {
          images.push(data.book_bank_image)
        }
        setData(data)
      }
    } catch (error) {
      console.log('error getUserDetail => ', error)
    }
  }

  useEffect(() => {
    getUserDetail()
  }, [])

  return <>
    <div className="row mb-3">
      <div className="thumbnail ml-2">
        <img
          className="lazy img-responsive"
          src={data?.picture === "" ? "/images/no-img.jpg" : `${data?.picture}`}
        />
      </div>

      <div className="ml-2">
        <h3>{data?.full_name ?? '-'}</h3>
        <h6>รหัสพนักงาน {data?.user_id ?? '-'}</h6>
        <h6 style={{ fontWeight: 'lighter' }}>{`${userType}`}</h6>
      </div>
    </div>
    <div>
      <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
        <li className="nav-item">
          <NavLink
            className={"nav-link "}
            activeClassName="active"
            to={`/user/detail/${id}`}
          >
            รายละเอียด
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={`/user/wallet/${id}`}
            className={"nav-link "}
            activeClassName="active"
          >
            ประวัติธุรกรรม
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to={`/user/order/${id}`}
            className={"nav-link "}
            activeClassName="active"
          >
            ประวัติการใช้งาน
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to={`/user/group/rider/${id}`}
            className={"nav-link "}
            activeClassName="active"
          >
            จัดการคนขับ
          </NavLink>
        </li>
        {
          data !== null && data.role.name_en === 'user' && <li className="nav-item">
            <NavLink to={`/user/affiliate/${id}`}
              className={"nav-link "}
              activeClassName="active"
            >
              Affiliate
            </NavLink>
          </li>
        }
      </ul>
    </div>
  </>
}