import "react-loadingmask/dist/react-loadingmask.css";
import "react-day-picker/lib/style.css";
import "moment/locale/th";
import "react-loadingmask/dist/react-loadingmask.css";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import React, { useEffect, useState } from "react";
import { api, header_token } from "../../config";

import { AffiliateTable } from "./AffiliateTable"
import Axios from "axios";
import { CustomOverlay } from "../../utils/Function";
import DayPickerInput from "react-day-picker/DayPickerInput";
import Select from "react-select";
import moment from "moment";
import swal from "sweetalert";

export const AffiliateNew = () => {
  // filter
  const [filterFrom, setFilterFrom] = useState();
  const [filterTo, setFilterTo] = useState();
  const [filterName, setFilterName] = useState("");
  const [filterType, setFilterType] = useState({ value: -1, label: "ทั้งหมด" });
  // table
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit, setLimit] = useState({ label: "50", value: 50, });
  const [total_count, settotal_count] = useState(0);
  const [loading, setLoading] = useState(false)
  const [typeOptions, setTypeOptions] = useState([
    { value: -1, label: "ทั้งหมด" },
    { value: 'user', label: "ผู้ใช้งาน" },
    { value: 'rider', label: "คนขับ" },
  ]);

  const [affilates, setAffiliate] = useState([])

  const getAffiliates = async () => {
    setLoading(true)
    try {
      let start = "";
      let end = "";

      let send_data = {
        user_id: filterName,
        status: 1,
      };

      if (filterFrom || filterTo) {
        start = moment(filterFrom, "DD/MM/YYYY").format("YYYY-MM-DD");
        end = moment(filterTo, "DD/MM/YYYY").format("YYYY-MM-DD");
        send_data.start_date = start
        send_data.end_date = end
      }

      const { data: { affiliate, pagination } } = await Axios.post(
        `${api}/admin/affiliate/list?page=${current_page}&limit=${limit.value}`,
        send_data,
        header_token,
      );

      setAffiliate(affiliate)
      settotal_count(pagination.total_count)

    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setLoading(false)
  }

  const cancelAffiliateReq = async (affiliateId, remark) => {
    try {
      await Axios.patch(
        `${api}/affiliate/${affiliateId}`,
        { status: 0, remark: remark },
        header_token,
      );

      return true
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const confirmAffiliateReq = async (affiliateId) => {
    try {
      const { data } = await Axios.patch(
        `${api}/affiliate/${affiliateId}`,
        { status: 2 },
        header_token,
      );

      return true
    } catch (error) {
      return false
    }
  }

  const onConfirm = async (affiliateId) => {
    try {
      swal({
        title: `ต้องการยืนยันคำขอเปิดใช้งาน Affiliate ใช่หรือไม่ ?`,
        confirmButtonText: "ใช่",
        cancelButtonText: "ย้อนกลับ",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willApprove) => {
        if (willApprove !== null) {
          let is_success = await confirmAffiliateReq(affiliateId);
          if (is_success) {
            getAffiliates();
            swal("อนุมัติสำเร็จ !!", {
              icon: "success",
            });
          } else {
            swal("เกิดข้อผิดพลาด !!", {
              icon: "error",
            });
          }
        }
      });
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const onCancel = async (affiliateId) => {
    try {
      swal({
        title: `ต้องการยกเลิกคำขอเปิดใช้งาน Affiliate ใช่หรือไม่ ?`,
        confirmButtonText: "ใช่",
        cancelButtonText: "ย้อนกลับ",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        content: {
          element: "input",
          attributes: {
            placeholder: 'เหตุผลของการยกเลิก'
          },
        },
      }).then(async (willApprove) => {
        if (willApprove !== null) {
          const inputValue = swal.getState().actions.confirm.value
          if (inputValue === "" || inputValue === null) {
            swal(`กรุณากรอกเหตุผลในการยกเลิกรายการขอคืนเงิน`, {
              icon: "error",
              button: false,
            });
          } else {
            let is_success = await cancelAffiliateReq(affiliateId, inputValue);
            if (is_success) {
              getAffiliates();
              swal("ยกเลิกสำเร็จ !!", {
                icon: "success",
              });
            } else {
              swal("เกิดข้อผิดพลาด !!", {
                icon: "error",
              });
            }
          }
        }
      });
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  useEffect(() => {
    getAffiliates()
  }, [])

  useEffect(() => {
    getAffiliates();
  }, [filterFrom, filterTo, current_page, filterName]);

  return (
    <div>
      <div className="row mb-2">
        <span className="ml-2 mt-auto mb-auto h4 ">
          {" "}
          {moment().format("LL")}
        </span>
        <div className="ml-auto mr-2">
          <p className="tx-13 text-muted mb-2">ค้นหาจากช่วงเวลา</p>
          <div className="InputFromTo">
            <DayPickerInput
              value={filterFrom}
              onDayChange={(e) => {
                setFilterFrom(e);
                if (!filterTo) {
                  setFilterTo(e);
                }
              }}
              placeholder={"วัน/เดือน/ปี"}
              format="L"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                locale: "th",
                localeUtils: MomentLocaleUtils,
              }}
            />{" "}
            ถึง{" "}
            <span className="InputFromTo-to">
              <DayPickerInput
                value={filterTo}
                onDayChange={(e) => {
                  setFilterTo(e);
                  if (!filterFrom) {
                    setFilterFrom(e);
                  }
                }}
                overlayComponent={CustomOverlay}
                placeholder={"วัน/เดือน/ปี"}
                format="L"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  locale: "th",
                  localeUtils: MomentLocaleUtils,
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">ทั้งหมด {total_count} รายการ</h4>
                </div>
                <div className="form-row align-items-center mb-2  ">
                  <div className=" mr-2" style={{ width: 200 }}>
                    <span className="tx-13 text-muted">ค้นหา</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      id="global_filter"
                      placeholder="ชื่อผู้ใช้งาน / Ref ID"
                      value={filterName}
                      onChange={(e) => {
                        setFilterName(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <AffiliateTable
                loading={loading}
                data={affilates}
                setLimit={setLimit}
                limit={limit}
                page_count={page_count}
                setcurrent_page={setcurrent_page}
                current_page={current_page}
                onConfirm={onConfirm}
                onCancel={onCancel}
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}