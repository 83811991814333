import "react-loadingmask/dist/react-loadingmask.css";

import React, { useEffect, useRef, useState } from "react";
import { api, header_token } from "../../config";

import Axios from "axios";
import LoadingMask from "react-loadingmask";
import { sort } from 'fast-sort';
import swal from "sweetalert";
import { useForm } from "react-hook-form";

export const SettingAreaForm = (props) => {
  const {
    onSubmit = () => { },
    title = '',
    initValue = null
  } = props
  const {
    handleSubmit: handleSubmitCreateArea,
    register: registerCreateArea,
    errors: errorsCreateArea,
    reset: resetCreate,
    setValue
  } = useForm();
  const closePass = useRef(null);
  const [provinces, setProvinces] = useState([])
  const [provincesReady, setProvincesReady] = useState([])
  const [isLastClickProvince, setIsLastClickProvince] = useState(null)

  const [districts, setDistricts] = useState([])
  const [districtsReady, setDistrictsReady] = useState([])
  const [isLastClickDistricts, setIsLastClickDistricts] = useState(null)

  const [subDistricts, setSubDistricts] = useState([])
  const [subDistrictsReady, setSubDistrictsReady] = useState([])
  const [isLastClickSubDistricts, setIsLastClickSubDistricts] = useState(null)

  const initData = async () => {
    try {
      let { data } = await Axios.get(`${api}/province`);

      setProvinces(sort(data).asc(p => p.name_th))
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const initDataForEdit = async (value) => {
    try {
      if (value !== null) {
        setValue("create_area_name", value.name_th);
        const _provinces = [], _districts = [], _sub_districts = []

        for (let index = 0; index < value.provinces.length; index++) {
          const _province = value.provinces[index];
          _provinces.push(_province.id)
          const _tempList = []
          if ((_province.districts !== undefined && _province.districts !== null) && _province.districts.length > 0) {
            for (let i = 0; i < _province.districts.length; i++) {
              _tempList.push(_province.districts[i].id)

              if (
                (_province.districts[i].sub_districts !== undefined && _province.districts[i].sub_districts !== null)
                && _province.districts[i].sub_districts.length > 0
              ) {
                const _tempListSub = []
                for (let subIndec = 0; subIndec < _province.districts[i].sub_districts.length; subIndec++) {
                  _tempListSub.push(_province.districts[i].sub_districts[subIndec].id)
                }
                _sub_districts.push({
                  districtsId: _province.districts[i].id,
                  subDistricts: _tempListSub,
                })
              }
            }
            _districts.push({
              provinceId: _province.id,
              districts: _tempList,
            })
          } else {
            let { data } = await Axios.get(`${api}/district/province/${_province.id}`);
            const listDis = []
            for (let index = 0; index < data.length; index++) {
              const _dis = data[index];
              listDis.push(_dis.id)
            }

            _districts.push({
              provinceId: _province.id,
              districts: listDis
            })
          }
        }

        setProvincesReady(_provinces)
        setDistrictsReady(_districts)
        setSubDistrictsReady(_sub_districts)
      }
    } catch (error) {
      console.log('initDataForEdit: ', error)
    }
  }

  const getDistrictsFromProvinceId = async (id) => {
    try {
      let { data } = await Axios.get(`${api}/district/province/${id}`);
      const listDis = []
      for (let index = 0; index < data.length; index++) {
        const _dis = data[index];
        listDis.push(_dis.id)
      }
      setDistricts(sort(data).asc(d => d.name_th))
      setDistrictsReady([
        ...districtsReady,
        {
          provinceId: id,
          districts: listDis
        }
      ])
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const getSubDistrictsFromDistrictsId = async (id) => {
    try {
      let { data } = await Axios.get(`${api}/sub-district/district/${id}`);
      const listSubDis = []
      for (let index = 0; index < data.length; index++) {
        const _subDis = data[index];
        listSubDis.push(_subDis.id)
      }

      setSubDistricts(sort(data).asc(s => s.name_th))
      setSubDistrictsReady([
        ...subDistrictsReady,
        {
          districtsId: id,
          subDistricts: listSubDis
        }
      ])
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const addProvinceToReady = async (province, isSelected) => {
    try {
      const isLast = isLastClickProvince !== null && isLastClickProvince.id === province.id
      if (isLast) {
        setIsLastClickProvince(null)
        setIsLastClickDistricts(null)
        setDistricts([])
        setSubDistricts([])
      } else {
        setIsLastClickDistricts(null)
        setDistricts([])
        setSubDistricts([])
        setIsLastClickProvince(province)
      }

      if (isSelected && isLast) {
        const temp = [...provincesReady]
        const index = temp.indexOf(province.id)
        if (index !== -1) {
          // remove province
          temp.splice(index, 1);
          setProvincesReady(temp)
          const tempDis = [...districtsReady]
          const disIndex = tempDis.findIndex((t) => t.provinceId === province.id)
          if (disIndex > -1) {
            const tempSub = [...tempDis[disIndex].districts]
            const tempSubReady = [...subDistrictsReady]
            for (let index = 0; index < tempSub.length; index++) {
              const dis = tempSub[index];
              const subDisIndex = tempSubReady.findIndex((t) => t.districtsId === dis.id)
              // remover sub districts
              tempSubReady.splice(subDisIndex, 1);
            }
            // remove districts
            tempDis.splice(disIndex, 1);
            setDistrictsReady(tempDis)
            setSubDistrictsReady(tempSubReady)
          }
        }
      } else if (!isSelected) {
        setProvincesReady([...provincesReady, province.id])
      }
    } catch (error) {
      console.log('addProvinceToReady error: ', error)
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const addDistrictToReady = async (districts, isSelected) => {
    try {
      const isLast = isLastClickDistricts !== null && isLastClickDistricts.id === districts.id
      if (isLast) {
        setIsLastClickDistricts(null)
        setSubDistricts([])
      } else {
        setIsLastClickDistricts(districts)
      }

      if (isSelected && isLast) {
        const temp = [...districtsReady]
        const provinceIndex = temp.findIndex((t) => t.provinceId === isLastClickProvince.id)
        if (provinceIndex > -1) {
          const index = temp[provinceIndex].districts.indexOf(districts.id)
          if (index !== -1) {
            temp[provinceIndex].districts.splice(index, 1);
            setDistrictsReady(temp)
            // remove sub districts
            const tempSub = [...subDistrictsReady]
            const disIndex = tempSub.findIndex((t) => t.districtsId === districts.id)
            if (disIndex > -1) {
              tempSub.splice(disIndex, 1);
              setSubDistrictsReady(tempSub)
            }
          }
        }
      } else if (!isSelected) {
        const temp = [...districtsReady]
        const proIndex = temp.findIndex((t) => t.provinceId === isLastClickProvince.id)
        if (proIndex > -1) {
          temp[proIndex].districts = [...temp[proIndex].districts, districts.id]
          setDistrictsReady(temp)
        } else {
          setDistrictsReady([
            ...districtsReady,
            {
              provinceId: isLastClickProvince.id,
              districts: [districts.id]
            }
          ])
        }
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const addSubDistrictToReady = async (subDistricts, isSelected) => {
    try {
      const isLast = isLastClickSubDistricts !== null && isLastClickSubDistricts.id === subDistricts.id
      if (isLast) {
        setIsLastClickSubDistricts(null)
      } else {
        setIsLastClickSubDistricts(subDistricts)
      }

      if (isSelected) {
        const temp = [...subDistrictsReady]
        const disIndex = temp.findIndex((t) => t.districtsId === isLastClickDistricts.id)
        if (disIndex > -1) {
          const index = temp[disIndex].subDistricts.indexOf(subDistricts.id)
          if (index !== -1) {
            temp[disIndex].subDistricts.splice(index, 1);
            setSubDistrictsReady(temp)
          }
        }
      } else {
        const temp = [...subDistrictsReady]
        const disIndex = temp.findIndex((t) => t.districtsId === isLastClickDistricts.id)
        if (disIndex > -1) {
          temp[disIndex].subDistricts = [...temp[disIndex].subDistricts, subDistricts.id]
          setSubDistrictsReady(temp)
        } else {
          setSubDistrictsReady([
            ...subDistrictsReady,
            {
              districtsId: isLastClickDistricts.id,
              subDistricts: [subDistricts.id]
            }
          ])
        }
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const onSubmited = async (value) => {
    try {
      const body = {
        name_th: value.create_area_name,
        name_en: value.create_area_name,
        branch_id: "",
        provinces: []
      }
      const provincesBody = []
      for (let index = 0; index < provincesReady.length; index++) {
        const pReady = provincesReady[index];
        // find dis with province
        const findDisIndex = districtsReady.findIndex((_d) => _d.provinceId === pReady)
        const _dis = []
        if (findDisIndex > -1) {
          for (let i = 0; i < districtsReady[findDisIndex].districts.length; i++) {
            const dReady = districtsReady[findDisIndex].districts[i];
            const findSubDisIndex = subDistrictsReady.findIndex((_d) => _d.districtsId === dReady)
            if (findSubDisIndex > -1) {
              const _tempSub = []
              for (let index = 0; index < subDistrictsReady[findSubDisIndex].subDistricts.length; index++) {
                const _sub = subDistrictsReady[findSubDisIndex].subDistricts[index];
                _tempSub.push({ id: _sub })
              }
              _dis.push({
                id: dReady,
                sub_districts: _tempSub
              })
            } else {
              _dis.push({
                id: dReady,
                sub_districts: null
              })
            }
          }
          provincesBody.push({
            id: pReady,
            districts: _dis
          })
        } else {
          provincesBody.push({
            id: pReady,
            districts: null
          })
        }
      }
      body.provinces = provincesBody
      onSubmit(body)
      closePass.current.click();
      resetData()
    } catch (error) {
      console.log('onSubmited: ', error)
    }
  }

  const resetData = async () => {
    try {
      setDistricts([])
      setSubDistricts([])
      setProvincesReady([])
      setDistrictsReady([])
      setSubDistrictsReady([])
      setIsLastClickProvince(null)
      setIsLastClickDistricts(null)
      setIsLastClickSubDistricts(null)
    } catch (error) {
      console.log('resetData: ', error)
    }
  }

  useEffect(() => {
    initData()
  }, [])

  useEffect(() => {
    if (initValue !== null)
      initDataForEdit(initValue)
  }, [initValue])

  useEffect(() => {
    if (isLastClickProvince !== null)
      getDistrictsFromProvinceId(isLastClickProvince.id)
  }, [isLastClickProvince])

  useEffect(() => {
    if (isLastClickDistricts !== null)
      getSubDistrictsFromDistrictsId(isLastClickDistricts.id)
  }, [isLastClickDistricts])

  return <div className="modal-dialog modal-mdx">
    <div className="modal-content">
      <div className="modal-header">
        <span>{title}</span>
        <button type="button" className="close" data-dismiss="modal" onClick={() => {
          if (initValue === null) {
            resetCreate()
            resetData()
          }
        }}>
          &times;
        </button>
      </div>
      <div className="modal-body">
        <form key="create-area" onSubmit={handleSubmitCreateArea(onSubmited)}>
          <div className="row" style={{ marginBottom: '5px' }}>
            <label
              htmlFor="create_area_name"
              className="col-sm-3 col-form-label"
            >
              ชื่อพื้นที่ที่เปิดให้บริการ
            </label>
            <div className="col-sm-8">
              <input
                id="create_area_name"
                name="create_area_name"
                ref={registerCreateArea({
                  required: true,
                })}
                className={`form-control ${errorsCreateArea.create_area_name
                  ? "error-input"
                  : ""
                  }`}
                placeholder="กทมและปริมณฑล"
              />
            </div>
          </div>
          <div style={{ display: 'flex', flex: 1, marginTop: '20px', marginBottom: '10px' }}>
            <div style={{ flex: 1 }}>จังหวัด</div>
            <div style={{ flex: 1 }}>อำเภอ/เขต</div>
            <div style={{ flex: 1 }}>ตำบล/แขวง</div>
          </div>
          <div style={{ display: 'flex', flex: 1 }}>
            <div
              style={{
                flex: 1,
                height: '50vh',
                overflowY: 'scroll',
                borderColor: 'black',
                borderWidth: '1px',
                borderRightWidth: '0px',
                borderStyle: 'solid',
              }}>
              {
                provinces.map((province, index) => {
                  const isSelected = provincesReady.find((v) => `${v}` === `${province.id}`) !== undefined

                  return <div key={index}
                    style={{
                      padding: '10px',
                      borderBottomWidth: '1px',
                      borderBottomStyle: 'solid',
                      cursor: 'pointer',
                      backgroundColor: isSelected ? '#5871ee' : 'white',
                      color: isSelected ? 'white' : 'black',
                    }}
                    onClick={() => addProvinceToReady(province, isSelected)}
                  >
                    {province.name_th ?? province.short_name_th}
                  </div>
                })
              }
            </div>
            <div
              style={{
                flex: 1,
                height: '50vh',
                overflowY: 'scroll',
                borderColor: 'black',
                borderWidth: '1px',
                borderRightWidth: '0px',
                borderStyle: 'solid',
              }}>
              {
                districts.map((district, index) => {
                  const findIndex = districtsReady.findIndex((disReady) => disReady.provinceId === isLastClickProvince.id)
                  let _isSelected = false

                  if (findIndex > -1) {
                    _isSelected = districtsReady[findIndex].districts.find((v) => `${v}` === `${district.id}`) !== undefined
                  }

                  return <div key={index}
                    style={{
                      padding: '10px',
                      borderBottomWidth: '1px',
                      borderBottomStyle: 'solid',
                      cursor: 'pointer',
                      backgroundColor: _isSelected ? '#5871ee' : 'white',
                      color: _isSelected ? 'white' : 'black',
                    }}
                    onClick={() => addDistrictToReady(district, _isSelected)}
                  >
                    {district.name_th ?? 'ไม่ทราบ'}
                  </div>
                })
              }
            </div>
            <div
              style={{
                flex: 1,
                height: '50vh',
                overflowY: 'scroll',
                borderColor: 'black',
                borderWidth: '1px',
                borderStyle: 'solid',
              }}>
              {
                subDistricts.map((sub, index) => {
                  const findIndex = subDistrictsReady.findIndex((subReady) => subReady.districtsId === isLastClickDistricts.id)
                  let _isSelected = false

                  if (findIndex > -1) {
                    _isSelected = subDistrictsReady[findIndex].subDistricts.find((v) => `${v}` === `${sub.id}`) !== undefined
                  }

                  return <div key={index}
                    style={{
                      padding: '10px',
                      borderBottomWidth: '1px',
                      borderBottomStyle: 'solid',
                      cursor: 'pointer',
                      backgroundColor: _isSelected ? '#5871ee' : 'white',
                      color: _isSelected ? 'white' : 'black',
                    }}
                    onClick={() => addSubDistrictToReady(sub, _isSelected)}
                  >
                    {sub.name_th ?? 'ไม่ทราบ'}
                  </div>
                })
              }
            </div>
          </div>
          <div className="row mr-2 mb-4" style={{ marginTop: '10px' }}>
            <div className="col-12">
              <div className="row">
                <button
                  data-dismiss="modal"
                  aria-label="Close"
                  className="btn btn-outline-primary mr-2 ml-auto "
                  ref={closePass}
                  type="button"
                  onClick={() => {
                    if (initValue === null) {
                      resetCreate()
                      resetData()
                    }
                  }}
                >
                  ยกเลิก
                </button>
                <button
                  type="submit"
                  className="btn btn-primary  mr-2 "
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div >
}