import { api, header_token, img_url } from "../../../config";
import { useEffect, useRef, useState } from 'react'

import Axios from "axios";
import Select from "react-select";
import { SettingBannerTable } from './SettingBannerTable'
import { checkImageFlowOld } from '../../../utils/Function'
import swal from "sweetalert";
import { useForm } from "react-hook-form";

export const SettingBannerDriverApp = () => {
  const showBannerFor = [
    { value: 'user', label: "User App" },
    { value: 'rider', label: "Driver App" },
  ]
  const [loading, setloading] = useState(false)
  const [data, setData] = useState([])
  const [banner, setBanner] = useState({ preview: "", base64: "" });
  const [showFor, setShowFor] = useState({ value: 'user', label: "User App" });
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);
  
  const closeAddPass = useRef(null);
  const closeEditPass = useRef(null);

  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = useForm();

  const getBannerForUser = async () => {
    try {
      setloading(true)
      const body = {
        type: 'rider'
      }

      let { data } = await Axios.post(
        `${api}/banner?page=${current_page}&limit=${limit.value}`,
        body,
        header_token
      );
      setData(data.banners)
      let cal_page_count = Math.ceil(data.pagination.total_count / limit);
      setpage_count(cal_page_count);
      setloading(false)
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      setloading(false)
    }
  }

  const creaetBanner = async (value) => {
    try {
      const body = {
        description: value.description,
        image: banner.base64 === "" ? banner.preview : banner.base64,
        type: showFor.value,
        available: true,
      }

      let res = await Axios.post(
        `${api}/admin/banner`,
        body,
        header_token
      );

      if (res.status === 201) {
        getBannerForUser();
        swal("เพิ่มเสร็จสิ้น !!", {
          icon: "success",
        });
      }

      reset()
      closeAddPass.current.click();
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const onSubmitEdit = async (value) => {
    try {
      const body = {
        description: value.description,
        image: value.image,
        type: value.type,
        available: value.available,
      }

      let res = await Axios.put(
        `${api}/admin/banner/${value.id}`,
        body,
        header_token
      );

      if (res.status === 200) {
        getBannerForUser();
        swal("แก้ไขสำเร็จ !!", {
          icon: "success",
        });
      }

      closeEditPass.current.click();
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const onSubmitDelete = async (id) => {
    try {
      let res = await Axios.delete(
        `${api}/admin/banner/${id}`,
        header_token
      );

      if (res.status === 200) {
        getBannerForUser();
        swal("ลบสำเร็จ !!", {
          icon: "success",
        });
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    getBannerForUser()
  }, [])

  return (
    <div>
      <div className="row mb-2">
        <button
          data-toggle="modal"
          data-target=".add-banner"
          className="btn btn-outline-primary btn-sm ml-auto mr-2"
          onClick={() => {
            reset()
            setBanner({ preview: "", base64: "" });
          }}
        >
          + เพิ่ม Banner
        </button>
      </div>
      <SettingBannerTable
        data={data}
        loading={loading}
        closeEditPass={closeEditPass}
        onSubmitEdit={onSubmitEdit}
        onSubmitDelete={onSubmitDelete}
        current_page={current_page}
        setcurrent_page={setcurrent_page}
        page_count={page_count}
      />
      <div className="modal add-banner" role="dialog">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <span>เพิ่ม Banner</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form key="create-banner" onSubmit={handleSubmit(creaetBanner)}>
                <div className="row mb-2">
                  <div className="col-12 border-bottom mb-3">
                    <div className="form-group row">
                      <label
                        htmlFor="description"
                        className="col-sm-3 col-form-label"
                      >
                        คำอธิบาย
                      </label>
                      <div className="col-sm-8">
                        <input
                          id="description"
                          name="description"
                          type="text"
                          ref={register({
                            required: true,
                          })}
                          className={`form-control ${errors.description
                            ? "error-input"
                            : ""
                            }`}
                          placeholder="คำอธิบาย"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="for_show">แสดงสำหรับ</label>
                      </div>
                      <div className="col-lg-8">
                        <Select
                          id="for_show"
                          name="for_show"
                          options={showBannerFor}
                          placeholder={"แสดงสำหรับ"}
                          value={showFor}
                          onChange={(e) => setShowFor(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="name">รูปที่แสดง</label>
                      </div>
                      <div className="col-sm-8">
                        <img
                          id="img-upload"
                          alt=""
                          src={
                            banner.base64 === ""
                              ? banner.preview === ""
                                ? ""
                                : checkImageFlowOld(banner.preview) ? img_url + banner.preview : banner.preview
                              : banner.preview
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/no-img.jpg";
                          }}
                          style={{ maxWidth: "100%", maxHeight: '540px' }}
                        />
                        <div className="row">
                          <div className="col-10">
                            <div className="input-group">
                              <input
                                type="file"
                                id="logo"
                                className={`form-control ${errors.logo ? "error-input" : ""}`}
                                accept=".png, .jpg, .jpeg"
                                onChange={async (e) => {
                                  if (e.target.files.length) {
                                    await setBanner({
                                      preview: URL.createObjectURL(e.target.files[0]),
                                      base64: await toBase64(e.target.files[0]),
                                    });
                                  }
                                }}
                                ref={(e) => {
                                  register(
                                    { name: "logo" },
                                    {
                                      validate: (value) => {
                                        // console.log("test");
                                        return banner.base64 || banner.preview
                                          ? true
                                          : false;
                                      },
                                    }
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <button
                              className="btn btn-light btn-sm mt-1"
                              onClick={() => {
                                setBanner({ preview: "", base64: "" });
                              }}
                            >
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mr-2 mb-4">
                  <div className="col-12">
                    <div className="row">
                      <button
                        data-dismiss="modal"
                        aria-label="Close"
                        className="btn btn-outline-primary mr-2 ml-auto "
                        ref={closeAddPass}
                        type="button"
                        onClick={() => {
                          reset()
                        }}
                      >
                        ยกเลิก
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary  mr-2 "
                      >
                        บันทึก
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}