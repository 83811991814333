import React, { useEffect, useRef, useState } from "react";
import _, { set } from "lodash";
import { api, header_token, image_size, img_url } from "../../config";
import { useHistory, useParams } from "react-router-dom";

import $ from "jquery";
import Axios from "axios";
import Image from 'react-bootstrap/Image';
import Select from "react-select";
import { checkImageFlowOld } from '../../utils/Function'
import dropify from "dropify";
import shortid from "shortid";
import swal from "sweetalert";
import { useForm } from "react-hook-form";

const service_type_options = [
  {
    label: "คิดค่าบริการตามระบบ",
    value: 0,
  },
  {
    label: "คิดค่าบริการตามจริง/มิเตอร์/แล้วแต่ตกลงกับผู้ให้บริการ",
    value: 1,
  },
];
const service_status = [
  { label: "ใช้งาน", value: 1 },
  { label: "ระงับ", value: 0 },
];
const option_types = [
  {
    label: "จำนวณเต็ม",
    value: 0,
  },
  {
    label: "เปอร์เซ็นต์ (%)",
    value: 1,
  },
];

const addon_option_types = [
  {
    label: "เปอร์เซ็นต์ (%)",
    value: 0,
  },
  {
    label: "จำนวณเต็ม",
    value: 1,
  },
];

export const CarServiceEdit = () => {
  const { register, handleSubmit, errors, control, trigger, setValue } =
    useForm();
  const { push } = useHistory();
  const { service_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [data, setdata] = useState();
  const [service_img, setservice_img] = useState({});
  const [status, setstatus] = useState(service_status[0]);
  const [service_type, setservice_type] = useState(service_type_options[0]);
  const [addon, setaddon] = useState([]);
  const [rings, setring] = useState([]);
  const [isOptionSelected, setIsOptionSelected] = useState(0)
  const [distanceRanges, setDistanceRanges] = useState([
    {
      at: 1,
      charge: 0,
    },
    {
      at: 0,
      charge: 0,
    },
  ]);
  const [serviceOptions, setServiceOptions] = useState([])
  const [isShowImageOldFlow, setShowImageOldFlow] = useState(false)
  const [images, setImages] = useState(null);
  const imageRef = useRef([]);

  const onAddDistanceRange = () => {
    setServiceOptions((prev) => {
      const last = prev[isOptionSelected].distance_ranges.pop();
      prev[isOptionSelected].distance_ranges = [
        ...prev[isOptionSelected].distance_ranges,
        { at: prev[isOptionSelected].distance_ranges[prev[isOptionSelected].distance_ranges.length - 1].at + 1, charge: 0 },
        { ...last },
      ]
      return [...prev];
    })
  };

  const onRemoveDistanceRange = () => {
    setServiceOptions((prev) => {
      const last = prev[isOptionSelected].distance_ranges.pop();
      prev[isOptionSelected].distance_ranges.pop();

      prev[isOptionSelected].distance_ranges = [
        ...prev[isOptionSelected].distance_ranges,
        { ...last },
      ]
      return [...prev];
    })
  };

  const onDistanceRangeChange = (name, index, value) => {
    setServiceOptions((prev) => {
      prev[isOptionSelected].distance_ranges[index][name] = name === "at" ? parseInt(value) : parseFloat(value);
      return [...prev];
    })
  };

  const onAddDistanceRing = () => {
    setring((prev) => {
      return [
        { at: prev.length + 1, distance: 1, dispatch_delay_sec: 30 },
        ...prev,
      ];
    });
  };

  var serviceOptionDefault = {
    "start_at_distance": 0,
    "start_fee": 0,
    "start_price": 0,
    "percentage_commission_distance": 0,
    "charge_per_kilometer": 0,
    "charge_per_point": 0,
    "commission_charge_per_point": 0,
    "commission_charge_per_point_type": 1,
    "distance_ranges": [
      {
        at: 1,
        charge: 0,
      },
      {
        at: 0,
        charge: 0,
      },
    ],
    "commission_per_distance": 0,
    "commission_per_distance_type": 1,
    "commission_rate_setting": [
      {
        commission_rate: 0
      }
    ]
  }

  const onAddServiceOption = () => {
    serviceOptionDefault.start_at_distance = serviceOptions[isOptionSelected].start_at_distance + 20
    setServiceOptions((prev) => {
      return [
        ...prev,
        serviceOptionDefault,
      ];
    });
  };

  const onRemoveServiceOption = () => {
    setServiceOptions((prev) => {
      prev.splice(isOptionSelected, 1)
      return [...prev];
    });
    setIsOptionSelected(0)
  };

  const onRemoveDistanceRing = (index) => {
    setring(prev => prev.filter((s, i) => (i !== index)))
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onSubmit = async (value) => {
    if (isLoading) return;
    setIsLoading(true);
    // maps new service options
    const options = []
    for (let index = 0; index < serviceOptions.length; index++) {
      const opt = serviceOptions[index];
      options.push({
        charge_per_kilometer: service_type.value === 0 ? opt.charge_per_kilometer : 0,
        charge_per_point: service_type.value === 0 ? opt.charge_per_point : 0,
        percentage_commission_distance: service_type.value === 0 ? opt.percentage_commission_distance : 0,
        start_fee: service_type.value === 1 ? opt.start_fee : 0,
        start_price: service_type.value === 1 ? opt.start_price : 0,
        commission_per_distance: opt.commission_per_distance,
        commission_per_distance_type: opt.commission_per_distance_type ?? 0,
        commission_charge_per_point: opt.commission_charge_per_point ?? 0,
        commission_charge_per_point_type: opt.commission_charge_per_point_type ?? 0,
        distance_ranges: serviceOptions[index].distance_ranges,
        start_at_distance: serviceOptions[index].start_at_distance,
        commission_rate_setting: [
          {
            name: "commission name 1",
            commission_type: 1,
            commission_rate: serviceOptions[index].commission_rate_setting[0].commission_rate,
            min_amount_use_this: 0,
            calculate_from_total_amount: false,
            final_commission_value: 0
          },
          {
            name: "commission name 2",
            commission_type: 1,
            commission_rate: 3,
            min_amount_use_this: 1000,
            calculate_from_total_amount: false,
            final_commission_value: 0
          }
        ]
      })
    }

    try {
      let send_data = {
        area_distance: parseInt(value.area_distance),
        detail: value.detail.trim(),
        short_description: value.short_description.trim(),
        name: value.name.trim(),
        short_description_en: value.short_description_en.trim(),
        name_en: value.name_en.trim(),
        picture: service_img.base64
          ? service_img.base64
          : service_img.preview
            ? service_img.preview
            : "",
        service_type: parseInt(service_type.value),
        status: parseInt(status.value),
        area_distance_extent: rings,
        service_type_option: options,
      };

      let response = await Axios.put(
        `${api}/admin/car-service/${service_id}`,
        send_data,
        header_token
      );

      // console.log(response);
      if (response.status === 201 || response.status === 200) {
        swal("แก้ไขบริการรถเสร็จสิ้น !!", {
          icon: "success",
          timer: 1000,
        });
        await getData();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onDistanceRingChange = (name, index, value) => {
    setring((prev) => {
      prev[index][name] = parseInt(value);
      return [...prev];
    });
  };

  const getData = async () => {
    let { data } = await Axios.get(
      `${api}/car-service/${service_id}`,
      header_token
    );
    // console.log(data);
    setdata(data);
    // for ring
    if (data.area_distance_extent !== undefined && data.area_distance_extent !== null && data.area_distance_extent.length > 0) {
      const newRings = []
      const resRing = data.area_distance_extent
      resRing.reverse().forEach((ring, index) => {
        newRings.push({
          at: index + 1,
          distance: ring.distance,
          dispatch_delay_sec: ring.dispatch_delay_sec,
        })
      });
      setring(newRings.reverse())
    } else {
      setring([
        {
          at: 1,
          distance: -1,
          dispatch_delay_sec: 90
        }
      ])
    }

    setServiceOptions(data.service_type_option ?? [serviceOptionDefault])

    setIsOptionSelected(0)
    setShowImageOldFlow(checkImageFlowOld(data.picture))
    setservice_img({
      preview: data.picture,
      base64: "",
    });
    setstatus(
      service_status.filter((e) => parseInt(e.value) === data.status)[0]
    );
    setservice_type(
      service_type_options.filter(
        (e) => parseInt(e.value) === data.service_type
      )[0]
    );
    setValue("commission_per_distance_type", data.commission_per_distance_type);
    setValue(
      "commission_charge_per_point_type",
      data.commission_charge_per_point_type
    );

    let service_addon = await Axios.get(
      `${api}/car-service/${service_id}/addon`
    );

    if (service_addon.data) {
      let tmp = [];
      service_addon.data.forEach((value) => {
        tmp.push({
          car_service_id: value.car_service_id,
          id: value.id,
          name: value.name,
          name_en: value.name_en,
          percentage_commission: value.percentage_commission,
          percentage_commission_type: value.percentage_commission_type,
          price: value.price,
          type: value.type,
          edit: false,
          new: false,
          image: {
            base64: null,
            preview: value.images !== null ? value.images[0] : null
          }
        });
      });
      setaddon(tmp);
    } else {
      setaddon([
        {
          id: "",
          name: "",
          price: 0,
          percentage_commission: 0,
          percentage_commission_type: 0,
          type: 0,
          edit: true,
          new: true,
          image: {
            base64: null,
            preview: null,
          }
        },
      ]);
    }

    $(".dropify").dropify();

    var drEventRider = $("#carservice-img").dropify();

    drEventRider.on("dropify.afterClear", async function (event, element) {
      await setservice_img({
        preview: "",
        base64: "",
      });
      trigger("service_img");
    });
  };

  const addAddon = async (send_data) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      // console.log('send_data: ', send_data)
      let res = await Axios.post(
        `${api}/admin/car-service/${service_id}/addon`,
        send_data,
        header_token
      );
      // console.log(res);
      if (res.status === 201) {
        await getData();
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const editAddon = async (addon_id, send_data) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      let res = await Axios.put(
        `${api}/admin/car-service/${service_id}/addon/${addon_id}`,
        send_data,
        header_token
      );
      // console.log(res);
      if (res.status === 201) {
        await getData();
        setImages(null)
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const deleteAddon = async (addon_id) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      let res = await Axios.delete(
        `${api}/admin/car-service/${service_id}/addon/${addon_id}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        await getData();
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return data ? (
    <div>
      <div className="row mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-2">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h4>แก้ไขบริการรถ</h4>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group row">
                              <div className="col-sm-4">
                                <label htmlFor="name">รูป Icon(150*75)</label>
                              </div>
                              <div className="col-sm-7" id="preview-img-div">
                                <div className="mb-2">
                                  {errors.service_img ? (
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                      style={{ width: "100%", padding: 5 }}
                                    >
                                      กรุณาอัพโหลดไฟล์
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <input
                                  type="file"
                                  id="carservice-img"
                                  className="dropify"
                                  data-max-file-size="3M"
                                  accept=".png, .jpg, .jpeg"
                                  data-default-file={
                                    isShowImageOldFlow ? `${img_url}${service_img.preview}` : service_img.preview
                                  }
                                  onChange={async (e) => {
                                    if (e.target.files.length) {
                                      let img_file = e.target.files[0];
                                      let base64img = await toBase64(img_file);
                                      // if (
                                      //   await image_size(base64img, 150, 75)
                                      // ) {
                                      setservice_img({
                                        preview:
                                          URL.createObjectURL(img_file),
                                        base64: base64img,
                                      });
                                      trigger("service_img");
                                      // } else {
                                      //   setTimeout(() => {
                                      //     $(
                                      //       "#preview-img-div .dropify-wrapper .dropify-clear"
                                      //     ).trigger("click");
                                      //     alert("ขนาดภาพเกินกว่าที่กำหนด");
                                      //   }, 50);
                                      // }
                                    }
                                  }}
                                  ref={(e) => {
                                    register(
                                      { name: "service_img" },
                                      {
                                        validate: (value) => {
                                          return service_img.base64 ||
                                            service_img.preview
                                            ? true
                                            : false;
                                        },
                                      }
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-4 col-form-label">
                                ชื่อ
                              </label>
                              <div className="col-sm-7">
                                <input
                                  id={`name`}
                                  name={`name`}
                                  type="text"
                                  className={`form-control ${errors.name ? "error-input" : ""
                                    }`}
                                  placeholder="ชื่อ"
                                  ref={register({
                                    required: true,
                                  })}
                                  defaultValue={data.name}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-4 col-form-label">
                                ชื่อภาษาอังกฤษ
                              </label>
                              <div className="col-sm-7">
                                <input
                                  id={`name_en`}
                                  name={`name_en`}
                                  type="text"
                                  className={`form-control ${errors.name_en ? "error-input" : ""
                                    }`}
                                  placeholder="ชื่อภาษาอังกฤษ"
                                  ref={register({
                                    required: true,
                                  })}
                                  defaultValue={data.name_en}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-4 col-form-label">
                                รูปแบบการบริการ
                              </label>
                              <div className="col-sm-7">
                                <input
                                  id={`detail`}
                                  name={`detail`}
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-control ${errors.detail ? "error-input" : ""
                                    }`}
                                  type="text"
                                  placeholder="รายละเอียด"
                                  defaultValue={data.detail}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-4 col-form-label">
                                คำอธิบายโดยย่อ
                              </label>
                              <div className="col-sm-7">
                                <input
                                  id={`short_description`}
                                  name={`short_description`}
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-control ${errors.short_description
                                    ? "error-input"
                                    : ""
                                    }`}
                                  type="text"
                                  placeholder="รายละเอียด"
                                  defaultValue={data.short_description}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-4 col-form-label">
                                คำอธิบายโดยย่อภาษาอังกฤษ
                              </label>
                              <div className="col-sm-7">
                                <input
                                  id={`short_description_en`}
                                  name={`short_description_en`}
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-control ${errors.short_description_en
                                    ? "error-input"
                                    : ""
                                    }`}
                                  type="text"
                                  placeholder="คำอธิบายโดยย่อภาษาอังกฤษ"
                                  defaultValue={data.short_description_en}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-4">
                                <label htmlFor="name">สถานะ</label>
                              </div>
                              <div className="col-sm-7">
                                <Select
                                  name="status"
                                  options={service_status}
                                  defaultValue={status}
                                  onChange={async (e) => {
                                    await setstatus(e);
                                    trigger("status");
                                  }}
                                  placeholder={"สถานะ..."}
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      borderColor: errors.status
                                        ? "red"
                                        : "#cdd4e0",
                                      "&:hover": {
                                        borderColor: errors.status
                                          ? "red"
                                          : "#cdd4e0",
                                      },
                                    }),
                                  }}
                                  ref={(e) => {
                                    register({ name: "status" });
                                  }}
                                />
                              </div>
                            </div>

                            {/* <div className=" row">
                              <button
                                className="ml-auto mr-4 btn btn-primary"
                                style={{ width: "15%" }}
                              >
                                บันทึก
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <hr style={{ margin: 0 }} />
                      <div style={{ padding: "1.25rem 1.25rem" }}>
                        <h4>ระยะการให้บริการ</h4>
                      </div>
                      {/* <hr style={{ margin: 0 }} /> */}
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group row">
                              <div className="col-sm-4 col-form-label">
                                ระยะทางรับงานครอบคลุม(km)
                              </div>
                              <div className="col-sm-7">
                                <input
                                  type="number"
                                  // placeholder="10"
                                  style={{ width: 100 }}
                                  id={`area_distance`}
                                  name={`area_distance`}
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-control ${errors.area_distance ? "error-input" : ""
                                    }`}
                                  defaultValue={data.area_distance}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-4 col-form-label">
                                การตั้งค่าการกระจายงาน
                              </div>
                              <div className="col-sm-7">
                                {
                                  rings.map((ring, index) => {
                                    return (
                                      <div key={ring.at + '' + index}>
                                        <div className="row col-form-label col-sm-2">
                                          ระยะที่ {index + 1}
                                        </div>
                                        <div className="row">
                                          <div className="col-form-label col-sm-3">
                                            ระยะทาง (km)
                                          </div>
                                          <input
                                            type={ring.at === 1 ? "text" : "number"}
                                            readOnly={ring.at === 1}
                                            min={1}
                                            // placeholder="10"
                                            style={{ width: 100 }}
                                            className={`form-control`}
                                            defaultValue={ring.at === 1 ? 'ทั้งหมด' : ring.distance}
                                            onBlur={(e) => {
                                              onDistanceRingChange(
                                                "distance",
                                                index,
                                                e.currentTarget.value
                                              );
                                            }}
                                          />
                                          <div className="col-form-label col-sm-3">
                                            ระยะเวลา (วินาที)
                                          </div>
                                          <input
                                            type={"number"}
                                            style={{ width: 100 }}
                                            className={`form-control`}
                                            min={1}
                                            defaultValue={ring.dispatch_delay_sec}
                                            onBlur={(e) => {
                                              onDistanceRingChange(
                                                "dispatch_delay_sec",
                                                index,
                                                e.currentTarget.value
                                              );
                                            }}
                                          />
                                          <div className="col-form-label col-sm-1" />
                                          {
                                            ring.distance !== -1 && <button
                                              className="btn btn-danger"
                                              type="button"
                                              onClick={() => {
                                                onRemoveDistanceRing(index)
                                              }}
                                            >
                                              -
                                            </button>
                                          }
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                                <div className="row col-form-label col-sm-2">
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => {
                                      onAddDistanceRing();
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr style={{ margin: 0 }} />
                      <div style={{ padding: "1.25rem 1.25rem" }}>
                        <h4>ค่าบริการ</h4>
                      </div>
                      {/* <hr style={{ margin: 0 }} /> */}
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group row">
                              <div className="col-sm-4">
                                รูปแบบการคิดว่าบริการ
                              </div>
                              <div className="col-sm-7">
                                <Select
                                  name="service_type"
                                  options={[
                                    {
                                      label: "คิดค่าบริการตามระบบ",
                                      value: 0,
                                    },
                                    {
                                      label:
                                        "คิดค่าบริการตามจริง/มิเตอร์/แล้วแต่ตกลงกับผู้ให้บริการ",
                                      value: 1,
                                    },
                                  ]}
                                  value={service_type}
                                  defaultValue={service_type}
                                  onChange={async (e) => {
                                    setservice_type(e);
                                    await trigger("service_type");
                                  }}
                                  placeholder={"รูปแบบ..."}
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      borderColor: errors.service_type
                                        ? "red"
                                        : "#cdd4e0",
                                      "&:hover": {
                                        borderColor: errors.service_type
                                          ? "red"
                                          : "#cdd4e0",
                                      },
                                    }),
                                  }}
                                  ref={(e) => {
                                    register({ name: "service_type" });
                                  }}
                                />
                              </div>
                            </div>

                            {
                              service_type.value !== 1 &&
                              <div className="row form-group" style={{ justifyItems: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3, overflowX: 'scroll', display: 'flex', flexWrap: 'nowrap' }}>
                                {
                                  serviceOptions.length > 0 && serviceOptions.map((opt, index) => {
                                    return <div className="row" key={'option-service-' + index} style={{ marginRight: 10, marginLeft: (index === 0 ? 2 : undefined), }}>
                                      <button
                                        className={"btn " + (isOptionSelected === index ? "btn-primary" : "btn-outline-primary")}
                                        style={{ marginRight: 10 }}
                                        type="button"
                                        onClick={() => {
                                          // onAddDistanceRing();
                                          setIsOptionSelected(index)
                                        }}
                                      >
                                        <span>เงื่อนไขที่ {index + 1}</span>
                                      </button>
                                    </div>
                                  })
                                }
                                {
                                  isOptionSelected !== 0 && <div className="row" style={{ marginRight: 20 }}>
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={() => {
                                        onRemoveServiceOption();
                                      }}
                                    >
                                      -
                                    </button>
                                  </div>
                                }
                                {
                                  serviceOptions.length < 10 && <div className="row">
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      onClick={() => {
                                        onAddServiceOption();
                                      }}
                                    >
                                      +
                                    </button>
                                  </div>
                                }
                              </div>
                            }

                            {service_type.value !== 1 ? (
                              <>
                                {serviceOptions[isOptionSelected] &&
                                  <>
                                    <div
                                      className="form-group row"
                                      key={`distance-range-${shortid.generate()}`}
                                    >
                                      <div className="col-sm-4">
                                        <span className="">
                                          เงื่อนไขระยะทาง
                                        </span>
                                      </div>
                                      <div className="row col-sm-8">
                                        <div className="col-sm-2">
                                          <input
                                            type="number"
                                            style={{ width: 100 }}
                                            defaultValue={serviceOptions[isOptionSelected].start_at_distance || 0}
                                            onBlur={(e) => {
                                              const val = parseFloat(e.currentTarget.value) || 0
                                              setServiceOptions((prev) => {
                                                prev[isOptionSelected].start_at_distance = val
                                                return [...prev]
                                              })
                                            }}
                                            min={isOptionSelected === 0 ? 0 : (serviceOptions[isOptionSelected - 1].start_at_distance + 1)}
                                            className={`form-control`}
                                          />
                                        </div>
                                        <div className="col-sm-2 d-flex align-items-center">
                                          กม. ขึ้นไป
                                        </div>
                                      </div>
                                    </div>
                                    {_.times(serviceOptions[isOptionSelected].distance_ranges.length, (i) => {
                                      const range = serviceOptions[isOptionSelected].distance_ranges[i];
                                      const disRanges = serviceOptions[isOptionSelected].distance_ranges

                                      if (i === 0) {
                                        return (
                                          <div
                                            className="form-group row"
                                            key={`distance-range-${shortid.generate()}`}
                                          >
                                            <div className="col-sm-4">
                                              <span className="">
                                                ค่าบริการเริ่มต้นในช่วง
                                              </span>
                                            </div>
                                            <div className="col-sm-8 row">
                                              <div className="col-sm-2">
                                                <input
                                                  type="number"
                                                  style={{ width: 100 }}
                                                  defaultValue={range.at}
                                                  onBlur={(e) => {
                                                    if (
                                                      e.currentTarget.value
                                                        .length === 0
                                                    ) {
                                                      e.currentTarget.value =
                                                        range.at;
                                                      return;
                                                    }
                                                    onDistanceRangeChange(
                                                      "at",
                                                      i,
                                                      e.currentTarget.value
                                                    );
                                                  }}
                                                  readOnly={
                                                    disRanges.length > 2
                                                  }
                                                  min={1}
                                                  className={`form-control`}
                                                />
                                              </div>
                                              <div className="col-sm-1 d-flex align-items-center">
                                                กม.แรก
                                              </div>
                                              <div className="col-sm-2">
                                                <input
                                                  type="number"
                                                  style={{ width: 100 }}
                                                  defaultValue={range.charge}
                                                  min={1}
                                                  onBlur={(e) => {
                                                    if (
                                                      e.currentTarget.value
                                                        .length === 0
                                                    ) {
                                                      e.currentTarget.value =
                                                        range.charge;
                                                      return;
                                                    }
                                                    onDistanceRangeChange(
                                                      "charge",
                                                      i,
                                                      e.currentTarget.value
                                                    );
                                                  }}
                                                  step={0.01}
                                                  className={`form-control`}
                                                />
                                              </div>
                                              <div className="col-sm-1 d-flex align-items-center">
                                                บาท
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }

                                      if (i === disRanges.length - 1) {
                                        return (
                                          <div
                                            key={`distance-range-${shortid.generate()}`}
                                          >
                                            <div className="form-group row">
                                              <div className="col-sm-4">
                                                <span className="">
                                                  เพิ่มช่วงระยะทาง
                                                </span>
                                              </div>
                                              <div className="col-sm-8">
                                                <button
                                                  className="btn btn-primary"
                                                  type="button"
                                                  onClick={onAddDistanceRange}
                                                >
                                                  +
                                                </button>
                                              </div>
                                            </div>
                                            <div className="form-group row">
                                              <div className="col-sm-4">
                                                <span className="">
                                                  ค่าบริการช่วงระยะทาง
                                                </span>
                                              </div>
                                              <div className="col-sm-8 row">
                                                <div className="col-sm-2">
                                                  <input
                                                    type="number"
                                                    style={{ width: 100 }}
                                                    defaultValue={
                                                      disRanges[i - 1].at + 0.001
                                                    }
                                                    readOnly
                                                    min={
                                                      disRanges[i - 1].at + 0.001
                                                    }
                                                    onBlur={(e) => {
                                                      if (
                                                        e.currentTarget.value
                                                          .length === 0
                                                      ) {
                                                        e.currentTarget.value =
                                                          range.at;
                                                        return;
                                                      }
                                                      onDistanceRangeChange(
                                                        "at",
                                                        i,
                                                        e.currentTarget.value
                                                      );
                                                    }}
                                                    className={`form-control`}
                                                  />
                                                </div>
                                                <div className="col-sm-1 d-flex align-items-center">
                                                  ขึ้นไปกม.ละ
                                                </div>
                                                <div className="col-sm-2">
                                                  <input
                                                    type="number"
                                                    style={{ width: 100 }}
                                                    defaultValue={range.charge}
                                                    min={1}
                                                    className={`form-control`}
                                                    onBlur={(e) => {
                                                      if (
                                                        e.currentTarget.value
                                                          .length === 0
                                                      ) {
                                                        e.currentTarget.value =
                                                          range.charge;
                                                        return;
                                                      }
                                                      onDistanceRangeChange(
                                                        "charge",
                                                        i,
                                                        e.currentTarget.value
                                                      );
                                                    }}
                                                    step={0.01}
                                                  />
                                                </div>
                                                <div className="col-sm-1 d-flex align-items-center">
                                                  บาท
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }

                                      return (
                                        <div
                                          className="form-group row"
                                          key={`distance-range-${shortid.generate()}`}
                                        >
                                          <div className="col-sm-4">
                                            <span className="">
                                              ค่าบริการช่วงระยะทางที่
                                            </span>
                                          </div>
                                          <div className="col-sm-8 row">
                                            <div className="col-sm-2">
                                              <input
                                                type="number"
                                                style={{ width: 100 }}
                                                defaultValue={
                                                  disRanges[i - 1].at + 0.001
                                                }
                                                readOnly
                                                min={disRanges[i - 1].at + 0.001}
                                                className={`form-control`}
                                              />
                                            </div>
                                            <div className="col-sm-1 d-flex align-items-center">
                                              ถึง
                                            </div>
                                            <div className="col-sm-2">
                                              <input
                                                type="number"
                                                style={{ width: 100 }}
                                                defaultValue={range.at}
                                                min={disRanges[i - 1].at + 1}
                                                readOnly={
                                                  i !== disRanges.length - 2
                                                }
                                                onBlur={(e) => {
                                                  if (
                                                    e.currentTarget.value.length ===
                                                    0
                                                  ) {
                                                    e.currentTarget.value =
                                                      range.at;
                                                    return;
                                                  }
                                                  if (
                                                    parseInt(
                                                      e.currentTarget.value
                                                    ) <=
                                                    disRanges[i - 1].at + 0.001
                                                  ) {
                                                    e.currentTarget.value =
                                                      range.at;
                                                    return;
                                                  }
                                                  onDistanceRangeChange(
                                                    "at",
                                                    i,
                                                    e.currentTarget.value
                                                  );
                                                }}
                                                className={`form-control`}
                                              />
                                            </div>
                                            <div className="col-sm-1 d-flex align-items-center">
                                              กม. ละ
                                            </div>
                                            <div className="col-sm-2">
                                              <input
                                                type="number"
                                                style={{ width: 100 }}
                                                defaultValue={range.charge}
                                                min={1}
                                                className={`form-control`}
                                                onBlur={(e) => {
                                                  if (
                                                    e.currentTarget.value.length ===
                                                    0
                                                  ) {
                                                    e.currentTarget.value =
                                                      range.charge;
                                                    return;
                                                  }
                                                  onDistanceRangeChange(
                                                    "charge",
                                                    i,
                                                    e.currentTarget.value
                                                  );
                                                }}
                                                step={0.01}
                                              />
                                            </div>
                                            <div className="col-sm-1 d-flex align-items-center">
                                              บาท
                                            </div>
                                            {i === disRanges.length - 2 && (
                                              <div className="col-sm-2">
                                                <button
                                                  className="btn btn-danger"
                                                  type="button"
                                                  onClick={onRemoveDistanceRange}
                                                >
                                                  -
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })}
                                    <div className="form-group row">
                                      <div className="col-sm-4">
                                        <span className="">
                                          ค่าคอมมิชชั่นตามระยะทาง
                                        </span>
                                      </div>
                                      <div className="col-sm-4">
                                        <input
                                          type="number"
                                          // placeholder="10"
                                          style={{ width: 100 }}
                                          id={`commission_per_distance-${isOptionSelected}`}
                                          name={`commission_per_distance-${isOptionSelected}`}
                                          key={`commission_per_distance-${isOptionSelected}`}
                                          // ref={register({
                                          //   required: true,
                                          // })}
                                          className={`form-control ${errors.commission_per_distance
                                            ? "error-input"
                                            : ""
                                            }`}
                                          defaultValue={
                                            // data.commission_per_distance
                                            // serviceOptions[isOptionSelected].commission_per_distance
                                            serviceOptions[isOptionSelected].commission_rate_setting !== null
                                              && serviceOptions[isOptionSelected].commission_rate_setting !== undefined
                                              && serviceOptions[isOptionSelected].commission_rate_setting.length !== 0 ?
                                              serviceOptions[isOptionSelected].commission_rate_setting[0].commission_rate
                                              : 0
                                          }
                                          step={0.01}
                                          onBlur={(e) => {
                                            // onChangeCommissionPerDistance(e.target.value)
                                            const val = parseFloat(e.target.value) || 0

                                            setServiceOptions((prev) => {
                                              prev[isOptionSelected].commission_rate_setting[0].commission_rate = val
                                              return [...prev]
                                            })
                                          }}
                                        />
                                      </div>
                                      <div className="col-sm-4">
                                        <Select
                                          key={`commission_per_distance_type-${isOptionSelected}`}
                                          name={`commission_per_distance_type-${isOptionSelected}`}
                                          options={[
                                            {
                                              label: "จำนวณเต็ม",
                                              value: 0,
                                            },
                                            {
                                              label: "เปอร์เซ็นต์ (%)",
                                              value: 1,
                                            },
                                          ]}
                                          defaultValue={
                                            option_types[
                                            // data.commission_per_distance_type
                                            serviceOptions[isOptionSelected].commission_per_distance_type
                                            ]
                                          }
                                          onChange={async (e) => {
                                            setServiceOptions((prev) => {
                                              prev[isOptionSelected].commission_per_distance_type = e.value
                                              return [...prev]
                                            })
                                          }}
                                          placeholder={"รูปแบบ..."}
                                          styles={{
                                            control: (base, state) => ({
                                              ...base,
                                              borderColor: errors.service_type
                                                ? "red"
                                                : "#cdd4e0",
                                              "&:hover": {
                                                borderColor: errors.service_type
                                                  ? "red"
                                                  : "#cdd4e0",
                                              },
                                            }),
                                          }}
                                          ref={(e) => {
                                            register({
                                              name: "commission_per_distance_type",
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <div className="col-sm-4">
                                        <span className="">
                                          ค่าบริการเพิ่มจุด/จุด(บาท)
                                        </span>
                                      </div>
                                      <div className="col-sm-7">
                                        <input
                                          type="number"
                                          // placeholder="15"
                                          style={{ width: 100 }}
                                          key={`charge_per_point-${isOptionSelected}`}
                                          id={`charge_per_point-${isOptionSelected}`}
                                          name={`charge_per_point-${isOptionSelected}`}
                                          ref={register({
                                            required: true,
                                          })}
                                          className={`form-control ${errors.charge_per_point
                                            ? "error-input"
                                            : ""
                                            }`}
                                          defaultValue={serviceOptions[isOptionSelected].charge_per_point}
                                          step={0.01}
                                          onBlur={(e) => {
                                            const val = parseFloat(e.target.value) || 0

                                            setServiceOptions((prev) => {
                                              prev[isOptionSelected].charge_per_point = val
                                              return [...prev]
                                            })
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {/* <div className="form-group row">
                                      <div className="col-sm-4">
                                        <span className="">
                                          ค่าคอมมิชชั่นบริการเพิ่มจุด
                                        </span>
                                      </div>
                                      <div className="col-sm-4">
                                        <input
                                          type="number"
                                          // placeholder="10"
                                          style={{ width: 100 }}
                                          id={`commission_charge_per_point-${isOptionSelected}`}
                                          name={`commission_charge_per_point-${isOptionSelected}`}
                                          key={`commission_charge_per_point-${isOptionSelected}`}
                                          ref={register({
                                            required: true,
                                          })}
                                          className={`form-control ${errors.commission_charge_per_point
                                            ? "error-input"
                                            : ""
                                            }`}
                                          defaultValue={
                                            serviceOptions[isOptionSelected].commission_charge_per_point
                                          }
                                          step={0.01}
                                          onBlur={(e) => {
                                            const val = parseFloat(e.target.value) || 0

                                            setServiceOptions((prev) => {
                                              prev[isOptionSelected].commission_charge_per_point = val
                                              return [...prev]
                                            })
                                          }}
                                        />
                                      </div>
                                      <div className="col-sm-4">
                                        <Select
                                          name={`commission_charge_per_point_type-${isOptionSelected}`}
                                          key={`commission_charge_per_point_type-${isOptionSelected}`}
                                          options={[
                                            {
                                              label: "จำนวณเต็ม",
                                              value: 0,
                                            },
                                            {
                                              label: "เปอร์เซ็นต์ (%)",
                                              value: 1,
                                            },
                                          ]}
                                          defaultValue={
                                            option_types[
                                            serviceOptions[isOptionSelected].commission_charge_per_point_type
                                            ]
                                          }
                                          onChange={async (e) => {
                                            setServiceOptions((prev) => {
                                              prev[isOptionSelected].commission_charge_per_point_type = e.value
                                              return [...prev]
                                            })
                                          }}
                                          placeholder={"รูปแบบ..."}
                                          styles={{
                                            control: (base, state) => ({
                                              ...base,
                                              borderColor: errors.service_type
                                                ? "red"
                                                : "#cdd4e0",
                                              "&:hover": {
                                                borderColor: errors.service_type
                                                  ? "red"
                                                  : "#cdd4e0",
                                              },
                                            }),
                                          }}
                                          ref={(e) => {
                                            register({
                                              name: "commission_charge_per_point_type",
                                            });
                                          }}
                                        />
                                      </div>
                                    </div> */}
                                  </>
                                }
                              </>
                            ) : (
                              <>
                                <div className="form-group row">
                                  <div className="col-sm-4">
                                    <span className="">ค่าเรียก(บาท)</span>
                                  </div>
                                  <div className="col-sm-7">
                                    <input
                                      type="number"
                                      // placeholder="25"
                                      style={{ width: 100 }}
                                      id={`start_fee`}
                                      name={`start_fee`}
                                      ref={register({
                                        required: true,
                                      })}
                                      className={`form-control ${errors.start_fee ? "error-input" : ""
                                        }`}
                                      defaultValue={
                                        serviceOptions.length > 0
                                          ? serviceOptions[0].start_fee
                                          : 0
                                      }
                                      onBlur={(e) => {
                                        const val = parseFloat(e.target.value) || 0

                                        if (serviceOptions.length > 0) {
                                          setServiceOptions((prev) => {
                                            prev[0].start_fee = val
                                            return [...prev]
                                          })
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                            <div className=" row">
                              <button
                                className="ml-auto mr-4 btn btn-primary"
                                style={{ width: "15%" }}
                              >
                                บันทึก
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div className="row mb-4">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>บริการเสริม</h4>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <div className="content">
                          <table id="example" className="table">
                            <thead>
                              <tr>
                                <th>รูปภาพ</th>
                                <th>บริการเสริม</th>
                                <th>ราคา(บาท)</th>
                                {/* <th>หักค่าบริการ</th> */}
                                <th width="80" className="text-center">
                                  Edit
                                </th>
                                <th width="80" className="text-center">
                                  Delete
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {addon.map((value, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <CarServiceEditImage
                                        key={`component-image-${index}`}
                                        index={index}
                                        value={value}
                                        setImages={(val) => {
                                          let tmp = [...addon];
                                          tmp[index].image = val;
                                          setaddon(tmp);
                                        }}
                                        toBase64={toBase64}
                                      />
                                    </td>
                                    <td className="end">
                                      {value.edit === true ? (
                                        <>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={value.name}
                                            onChange={(e) => {
                                              let tmp = [...addon];
                                              tmp[index].name = e.target.value;
                                              setaddon(tmp);
                                            }}
                                            placeholder="ชื่อบริการเสริม"
                                          />
                                          <br />
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={value.name_en}
                                            onChange={(e) => {
                                              let tmp = [...addon];
                                              tmp[index].name_en =
                                                e.target.value;
                                              setaddon(tmp);
                                            }}
                                            placeholder="ชื่อบริการเสริมภาษาอังกฤษ"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {value.name}
                                          <br />
                                          {value.name_en}
                                        </>
                                      )}
                                    </td>
                                    <td className="cost">
                                      {value.edit === true ? (
                                        <>
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={value.price}
                                            onChange={(e) => {
                                              let tmp = [...addon];
                                              tmp[index].price = parseFloat(
                                                e.target.value
                                              );
                                              setaddon(tmp);
                                            }}
                                          />
                                          <br />
                                          <div
                                            className="d-flex align-items-center"
                                            style={{ height: 38 }}
                                          >
                                            <input
                                              type="checkbox"
                                              name="type"
                                              checked={value.type === 1}
                                              onChange={(e) => {
                                                let tmp = [...addon];
                                                tmp[index].type = e.target
                                                  .checked
                                                  ? 1
                                                  : 0;
                                                setaddon(tmp);
                                              }}
                                            />
                                            <span className="ml-2">
                                              คิดค่าบริการเป็นราคาเหมา
                                            </span>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {value.price}
                                          <br />
                                          <div
                                            className="d-flex align-items-center"
                                            style={{ height: 38 }}
                                          >
                                            <input
                                              type="checkbox"
                                              name="type"
                                              checked={value.type === 1}
                                              readOnly
                                            />
                                            <span className="ml-2">
                                              คิดค่าบริการเป็นราคาเหมา
                                            </span>
                                          </div>
                                        </>
                                      )}
                                    </td>
                                    <td className="edit">
                                      {value.edit === false ? (
                                        <button
                                          type="button"
                                          className="btn btn-link"
                                        >
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa tx-20 text-muted fa-edit"
                                            onClick={() => {
                                              let tmp = [...addon];
                                              tmp[index].edit =
                                                !tmp[index].edit;
                                              setaddon(tmp);
                                            }}
                                          ></i>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn btn-link"
                                          disabled={isLoading}
                                          onClick={async () => {
                                            let tmp = [...addon];

                                            if (tmp[index].new === true) {
                                              // console.log("add");
                                              let send_data = {
                                                name: value.name.trim(),
                                                name_en: value.name_en.trim(),
                                                percentage_commission:
                                                  parseFloat(
                                                    value.percentage_commission
                                                  ),
                                                percentage_commission_type:
                                                  value.percentage_commission_type,
                                                price: parseFloat(value.price),
                                                type: value.type,
                                                images: [value.image.base64]
                                              };
                                              await addAddon(send_data);
                                            } else {
                                              let send_data = {
                                                name: value.name.trim(),
                                                name_en: value.name_en.trim(),
                                                percentage_commission:
                                                  parseFloat(
                                                    value.percentage_commission
                                                  ),
                                                percentage_commission_type:
                                                  value.percentage_commission_type,
                                                price: parseFloat(value.price),
                                                type: value.type,
                                                images: [value.image.base64 !== null ? value.image.base64 : value.image.preview]
                                              };
                                              await editAddon(
                                                value.id,
                                                send_data
                                              );
                                            }

                                            // getDataCostDistance();
                                            tmp[index].edit = !tmp[index].edit;
                                            tmp[index].new = false;

                                            setaddon(tmp);
                                          }}
                                        >
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa tx-20 text-muted fa-save"
                                          ></i>
                                        </button>
                                      )}
                                    </td>

                                    <td className="trash">
                                      <button
                                        type="button"
                                        className="btn btn-link"
                                        onClick={() => {
                                          if (value.edit === false) {
                                            swal({
                                              title: `ต้องการลบหรือไม่ ?`,
                                              // text:
                                              //   "Once deleted, you will not be able to recover this imaginary file!",
                                              confirmButtonText: "ลบ",
                                              cancelButtonText: "ยกเลิก",
                                              icon: "warning",
                                              buttons: true,
                                              dangerMode: true,
                                            }).then(async (willDelete) => {
                                              if (willDelete) {
                                                let is_delete = deleteAddon(
                                                  value.id
                                                );
                                                if (is_delete) {
                                                  swal("ลบเสร็จสิ้น !!", {
                                                    icon: "success",
                                                  });
                                                } else {
                                                  swal("เกิดข้อผิดพลาด !!", {
                                                    icon: "error",
                                                  });
                                                }
                                              }
                                            });
                                          }
                                        }}
                                        disabled={isLoading}
                                      >
                                        <i
                                          style={{ cursor: "pointer" }}
                                          className="fa fa-trash-o tx-20 text-muted"
                                        ></i>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td
                                  className="new-row"
                                  colSpan="7"
                                  onClick={() => {
                                    let tmp = [...addon];
                                    tmp.push({
                                      id: "",
                                      name: "",
                                      name_en: "",
                                      price: 0,
                                      percentage_commission: 0,
                                      percentage_commission_type: 0,
                                      type: 0,
                                      edit: true,
                                      new: true,
                                      image: {
                                        base64: null,
                                        preview: null
                                      }
                                    });
                                    setaddon(tmp);
                                  }}
                                >
                                  + Add
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  ) : null;
};

export const CarServiceEditImage = ({
  index,
  value,
  setImages,
  toBase64
}) => {
  const imageRef = useRef(null);
  const [img, setImg] = useState(null)

  useEffect(() => {
    console.log(`div-image-for-addon-${index}`)
  }, [])

  return <div
    key={`div-image-for-addon-${index}`}
    style={{
      border: '1px solid #cdd4e0',
      width: '80px',
      height: 'auto',
      minHeight: '100px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer'
    }}
    onClick={() => {
      if (value.edit === true) {
        imageRef.current.click()
      } else {
        if (value.image !== null && value.image.preview !== null) {
          window.open(`${value.image.preview}`, '_blank', 'noopener,noreferrer')
        }
      }
    }}
  >
    <span
      hidden={value.image.preview !== null}
      style={{ fontSize: '10px' }}
    >
      ไม่มีรูปภาพ
    </span>
    <Image
      hidden={value.image.preview === null}
      style={{ cursor: 'pointer', width: '100%', height: 'auto' }}
      key={`image-for-addon-${index}`}
      src={
        img !== null && img.preview !== ''
          ? img.preview
          : value.image.preview !== null
            ? value.image.preview
            : ''
      }
      thumbnail
    />
    <div className="row" hidden>
      <div className="col-10">
        <div className="input-group">
          <input
            key={`input-image-for-addon-${index}`}
            type="file"
            id="image"
            className={`form-control`}
            accept=".png, .jpg, .jpeg"
            ref={imageRef}
            onChange={async (e) => {
              if (e.target.files.length) {
                const preview = URL.createObjectURL(e.target.files[0])
                const base64 = await toBase64(e.target.files[0])
                setImg({
                  preview: preview,
                  base64: base64,
                })
                setImages({
                  preview: preview,
                  base64: base64,
                })
              }
            }}
          />
        </div>
      </div>
    </div>
  </div>
}