import "react-loadingmask/dist/react-loadingmask.css";
import "react-day-picker/lib/style.css";
import "moment/locale/th";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import React, { useState } from "react";
import { api, header_token, img_url, wallet_option } from "../../config";

import Axios from "axios";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { ExportBalance } from './ExportBalance'
import Select from "react-select";
import moment from "moment";
import swal from "sweetalert";

export const ReportUser = () => {
  const [reportSelected, setReportSelected] = useState({ value: 0, label: "รายงานยอดเงินคงเหลือ" });
  const [userSelected, setUserSelected] = useState({ value: 0, label: "ทั้งหมด" });
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [startDate, setStartDate] = useState(moment().format("DD/MM/YYYY"));
  const [endDate, setEndDate] = useState(moment().format("DD/MM/YYYY"));

  const reportOptions = [
    { value: 0, label: "รายงานยอดเงินคงเหลือ" },
    { value: 1, label: "รายงานการปิดบัญชี" },
  ]

  const userOptions = [
    { value: 0, label: "ทั้งหมด" },
    { value: 1, label: "ผู้ใช้งาน" },
    { value: 2, label: "ผู้ใช้งานธุรกิจ" },
    { value: 3, label: "คนขับ" },
  ]

  const exportExcel = async () => {
    setLoading(true);
    try {
      let userType = ['user', 'user_business', 'rider']
      if (userSelected.value === 1) {
        userType = ['user']
      } else if (userSelected.value === 2) {
        userType = ['user_business']
      } else if (userSelected.value === 3) {
        userType = ['rider']
      }

      let sendBody = {
        role: userType,
        status: -1,
      };

      const _exportData = [];
      let { data } = await Axios.post(
        `${api}/admin/user/filter?page=1&limit=9999999`,
        sendBody,
        header_token
      );

      if (data.users.length > 0) {
        if (reportSelected.value === 0) {
          if (data.users) {
            data.users.forEach((value, index) => {
              _exportData.push({
                user_id: value.user_id,
                name: value.full_name,
                role: value.role.name_th,
                credit: value.credit,
                credit_bonus: value.credit_bonus
              });
            });
            setExportData(_exportData);
            setTrigger(true);
          }
        } else if (reportSelected.value === 1) {
          let start = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
          let end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
          let link = ''

          sendBody['start_date'] = start
          sendBody['end_date'] = end

          const { data: { fileUrl } } = await Axios.post(
            `${api}/admin/deleted/export`,
            sendBody,
            header_token
          );

          if (fileUrl !== null && fileUrl !== undefined)
            link = fileUrl

          if (link !== '') {
            window.open(link, "_blank")
          } else {
            swal("ไม่สามารถโหลดไฟล์ได้กรุณาลองใหม่อีกครัั้ง", {
              icon: "warning",
            });
          }
        }
      } else {
        swal(`ไม่มีข้อมูล`, {
          icon: "warning",
          button: false,
        });
      }

    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setLoading(false);
  }

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div
              className="card-body"
              style={{
                minHeight: 500,
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div>
                <div className="mr-1" style={{ width: 250, marginBottom: '10px' }}>
                  <span className="tx-13 text-muted">ประเภทรายงาน</span>
                  <Select
                    value={reportSelected}
                    onChange={(e) => {
                      setReportSelected(e);
                    }}
                    options={reportOptions}
                    placeholder={"ประเภทรายงาน"}
                  />
                </div>

                <div style={{ display: 'flex', gap: '20px', marginBottom: '5px' }} hidden={reportSelected.value !== 1 ? true : undefined}>
                  <div>
                    <div>
                      <span className="tx-13 text-muted">วันเริ่มต้น</span>
                    </div>
                    <DayPickerInput
                      value={moment(startDate, "DD/MM/YYYY").format("YYYY/MM/DD")}
                      onDayChange={(e) => {
                        setStartDate(e);
                      }}
                      inputProps={{ style: { borderRadius: '5px', borderWidth: '0.5px', height: '40px', padding: '10px' } }}
                      placeholder={"วัน/เดือน/ปี"}
                      format="L"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      dayPickerProps={{
                        locale: "th",
                        localeUtils: MomentLocaleUtils,
                      }}
                    />
                  </div>
                  <div>
                    <div>
                      <span className="tx-13 text-muted">วันสิ้นสุด</span>
                    </div>
                    <div style={{ flex: 1 }}>
                      <DayPickerInput
                        value={moment(endDate, "DD/MM/YYYY").format("YYYY/MM/DD")}
                        onDayChange={(e) => {
                          setEndDate(e);
                        }}
                        inputProps={{ style: { borderRadius: '5px', borderWidth: '0.5px', height: '40px', padding: '20px' } }}
                        placeholder={"วัน/เดือน/ปี"}
                        format="L"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                          locale: "th",
                          localeUtils: MomentLocaleUtils,
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="mr-1" style={{ width: 250, marginBottom: '10px' }}>
                  <span className="tx-13 text-muted">ประเภทผู้ใช้งาน</span>
                  <Select
                    value={userSelected}
                    onChange={(e) => {
                      setUserSelected(e);
                    }}
                    options={userOptions}
                    placeholder={"ประเภทผู้ใช้งาน"}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <button
                  type="button"
                  className="btn btn-primary mr-2 ml-2"
                  style={{ marginTop: -2, padding: `5px 10px 5px 10px`, width: '150px' }}
                  onClick={async () => {
                    if (!trigger && !loading) {
                      await exportExcel();
                    }
                  }}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                  {` `}Export
                </button>
                <ExportBalance
                  data={exportData}
                  trigger={trigger}
                  setTrigger={setTrigger}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}