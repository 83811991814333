import "moment/locale/th";

import { api, header_token } from "../config";

import Axios from "axios";
import ThaiBahtText from 'thai-baht-text'
import moment from "moment";
import pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";
import swal from "sweetalert";

pdfMake.vfs = pdfFonts;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

export const convertPaidByText = (value) => {
  if (value.toLowerCase() === 'sender') {
    return 'ผู้ส่ง'
  } else {
    return 'ผู้รับ'
  }
}

export const checkImageFlowOld = (image) => {
  if (image === undefined) return ""

  return image.toString().indexOf('.com') === -1
}

export const getMethodText = (transaction) => {
  if (transaction.method === '') {
    if (transaction.type === 3)
      // return <Link to={`/order/detail/${transaction.code}`}>{transaction.code}</Link>
      return transaction.code
    if (transaction.type === 1 || transaction.type === 2)
      return `${transaction.bank_name_th}(${transaction.bank_short_name})`
  } else if (transaction.method.toLowerCase() === 'full') {
    return 'เครดิต/เดบิตการ์ด'
  } else if (transaction.method.toLowerCase() === 'promptpay') {
    return 'PromptPay'
  } else if (transaction.method.toLowerCase() === 'promotion_code') {
    return `โปรโมชัน ${transaction.code}`
  }

  return transaction.method
}

export const getRoleNameText = (role) => {
  if (role === 'user_business') {
    return 'ผู้ใช้งานธุรกิจ'
  } else if (role === 'user') {
    return 'ผู้ใช้งานทั่วไป'
  }

  return role.toString()
}

const getBase64ImageFromURL = (url) => {
  try {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        if (ctx !== null) {
          ctx.drawImage(img, 0, 0);
          var dataURL = canvas.toDataURL("image/png");

          resolve(dataURL);
        }

      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  } catch (error) {

  }
}


export const printPDF = async (id) => {
  try {
    // get data from server
    let { data: detail } = await Axios.get(`${api}/user/order/invoice/${id}`, header_token);
    const imageData = await getBase64ImageFromURL('/images/imove_receipt_logo.png')

    if (detail === null) {
      swal(`ไม่มีข้อมูล`, {
        icon: "warning",
        button: false,
      });
    } else {
      var docDefinition = {
        info: {
          title: `IM${detail?.invoice_no || '-'}`,
        },
        content: [
          {
            columns: [
              { image: imageData, width: 120, height: 30, margin: [0, 10], },
              { width: '75%', text: 'ใบเสร็จรับ/ใบกำกับภาษี', fontSize: 28, alignment: 'right', margin: [0, 10], bold: true }
            ],
          },
          { text: 'บริษัท ไอมูฟ เดลิเวอรี่ จำกัด', fontSize: 16 },
          { text: 'เลขที่ 2/35 หมู่บ้าน H Cape ถ.สุขาภิบาล 2 ประเวศ', fontSize: 16 },
          { text: 'เขตประเวศ กรุงเทพมหานคร 10250', fontSize: 16 },
          { text: 'เลขผู้เสียภาษี/ Tax ID 0105567041771', fontSize: 16 },
          { text: 'เบอร์โทร : 099-3938592', fontSize: 16, margin: [0, 0, 0, 10], },
          {
            canvas:
              [
                {
                  type: 'line',
                  x1: 0, y1: 0,
                  x2: 595 - 2 * 40, y2: 0,
                  lineWidth: 2,
                },
              ]
          },
          {
            columns: [
              { width: '25%', text: 'ลูกค้า / Customer', fontSize: 14, margin: [0, 5, 0, 0], bold: true },
              { width: '50%', text: detail?.customer_name || '-', fontSize: 14, margin: [0, 5, 0, 0], bold: true },
              { width: '25%', text: `เลขที่ / No. IM${detail?.invoice_no || '-'}`, alignment: 'right', fontSize: 14, margin: [0, 5, 0, 0], bold: true }
            ],
          },
          {
            columns: [
              { width: '25%', text: 'ที่อยู่ / Address', fontSize: 14, bold: true },
              { width: '50%', text: detail?.customer_address || '-', fontSize: 14, bold: true },
              { width: '25%', text: `วันที่ / Date. ${moment(detail?.date).format("DDMMYYYY")}`, alignment: 'right', fontSize: 14, bold: true }
            ],
          },
          {
            columns: [
              { width: '25%', text: 'เลขผู้เสียภาษี / Tax ID', fontSize: 14, bold: true },
              { width: '50%', text: detail?.customer_tax_id || '-', fontSize: 14, bold: true },
            ],
          },
        ],
        defaultStyle: {
          font: 'THSarabunNew'
        }
      };

      if (detail.contact !== '')
        docDefinition.content.push({
          columns: [
            { width: '25%', text: 'ติดต่อ / Contact', fontSize: 14, bold: true },
            { width: '50%', text: detail.contact, fontSize: 14, bold: true },
          ],
        },)

      docDefinition.content.push({
        style: 'section',
        margin: [0, 20, 0, 5],
        table: {
          widths: ['80%', '20%'],
          body: [
            [
              { text: 'รายการ', fontSize: 16, bold: true, color: 'white', fillColor: "#3095db", bold: true, margin: [10, 0, 0, 0] },
              { text: 'ราคา', alignment: 'center', fontSize: 16, bold: true, color: 'white', fillColor: "#3095db", bold: true }
            ]
          ],
        },
        layout: 'noBorders'
      },)
      docDefinition.content.push({
        columns: [
          { width: '80%', text: 'ค่าขนส่ง', fontSize: 14, bold: true },
          { width: '20%', text: `${detail.car_service_cost.toLocaleString()} -`, alignment: 'right', fontSize: 14, bold: true },
        ],
      })

      detail.services.length > 0 && docDefinition.content.push({
        columns: [
          { width: '80%', text: 'ค่าบริการเสริม (vat %7)', fontSize: 14, bold: true },
        ],
      })

      detail.services.map((val, index) => {
        docDefinition.content.push({
          columns: [
            { width: '80%', text: `${index + 1}. ${val.name}`, fontSize: 14, bold: true },
            { width: '20%', text: `${val.price.toLocaleString()} -`, alignment: 'right', fontSize: 14, bold: true },
          ],
        })
      })

      var _summary = [
        {
          columns: [
            { width: '80%', text: 'ยอดรวม: ', alignment: 'right', fontSize: 14, bold: true, margin: [0, 40, 0, 0] },
            { width: '20%', text: `${(detail.car_service_cost + detail.car_service_addon_cost).toLocaleString()} -`, alignment: 'right', fontSize: 14, bold: true, margin: [0, 40, 0, 0] },
          ],
        },
        {
          columns: [
            { width: '80%', text: 'ส่วนลด: ', alignment: 'right', fontSize: 14, bold: true },
            { width: '20%', text: `${detail.promotion_discount.toLocaleString() || 0} -`, alignment: 'right', fontSize: 14, bold: true },
          ],
        },
        { canvas: [{ type: 'line', x1: (595 - 2 * 40) - ((595 - 2 * 40) * 0.2), y1: 0, x2: 595 - 2 * 40, y2: 0, lineWidth: 2 }], margin: [0, 20, 0, 0], },
        {
          style: 'section',
          margin: [0, 2, 0, 2],
          table: {
            widths: ['40%', '40%', '20%'],
            body: [
              [
                { text: '' },
                { text: 'ยอมรวมสุทธิ (vat %7): ', alignment: 'right', fontSize: 16, bold: true },
                { text: `${detail.total.toLocaleString() || 0} -`, alignment: 'center', fontSize: 16, bold: true, color: 'white', fillColor: "#3095db" }
              ]
            ],
          },
          layout: 'noBorders'
        },
        { canvas: [{ type: 'line', x1: (595 - 2 * 40) - ((595 - 2 * 40) * 0.2), y1: 0, x2: 595 - 2 * 40, y2: 0, lineWidth: 2 }] },
        {
          columns: [
            { width: '80%', text: '' },
            { width: '20%', text: `(${detail.total == 0 ? 'ศูนย์บาทถ้วน' : ThaiBahtText(detail.total || 0)})`, alignment: 'center', fontSize: 12, bold: true, margin: [0, 5, 0, 0] },
          ],
        },
      ]

      docDefinition.content.push(..._summary)

      pdfMake.createPdf(docDefinition).open()
    }
  } catch (error) {
    swal(`${error.response.data.message}`, {
      icon: "error",
      button: false,
    });
  }
}

export function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -109 }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

export function convertRefundStatusToText(status) {
  switch (status) {
    case 0:
      return 'ไม่สำเร็จ'
    case 1:
      return 'รอดำเนินการ'
    case 2:
      return 'กำลังดำเนินการ'
    case 3:
      return 'สำเร็จ'

    default:
      return 'สถานะไ่ม่ถูกต้อง'
  }
}

export function convertRefundMethodToText(md) {
  switch (md.toLowerCase()) {
    case 'full':
      return 'เครดิตการ์ด/เดบิตการ์ด'
    case 'promptpay':
      return 'PromptPay'
    case 'wallet':
      return 'Wallet'

    default:
      return md
  }
}

export function convertAffiliateStatusToText(status) {
  switch (status) {
    case 0:
      return 'ยกเลิก'
    case 1:
      return 'รออนุมัติ'
    case 2:
      return 'กำลังใช้งาน'
    case 3:
      return 'ระงับ'

    default:
      return status
  }
}