import "react-loadingmask/dist/react-loadingmask.css";

import LoadingMask from "react-loadingmask";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { convertRefundStatusToText } from "../../utils/Function";

const limitOptions = [
  { label: "50", value: 50, },
  { label: "100", value: 100, },
  { label: "150", value: 150, },
]

export const RefundTable = (props) => {
  const {
    loading = false,
    data = [],
    setLimit = () => { },
    limit = { label: "50", value: 50 },
    page_count = 0,
    setcurrent_page = () => { },
    current_page = 1,
    onSubmitRefund = () => { },
    onCancelRefund = () => { },
    isShowAction = true,
    confirmText = 'สำเร็จ'
  } = props

  return <>
    <LoadingMask loading={loading} text={"loading..."}>
      <div style={{ minHeight: 500 }}>
        <div className="table-responsive">
          <table id="dataTable" className="table ">
            <thead>
              <tr>
                <th style={{ minWidth: 150 }}>Order</th>
                <th style={{ minWidth: 150 }}>วันที่ยื่นขอ</th>
                <th style={{ minWidth: 200, textAlign: 'center' }}>ผู้ใช้งาน</th>
                <th style={{ minWidth: 150, textAlign: 'center' }}>จำนวน (บาท)</th>
                <th style={{ minWidth: 200 }}>ช่องทางชำระเงิน</th>
                <th style={{ minWidth: 250 }}>หมายเหตุ</th>
                <th style={{ minWidth: 150 }}>สถานะ</th>
                {
                  isShowAction && <th style={{ minWidth: 150 }}></th>
                }
              </tr>
            </thead>
            <tbody>
              {
                data.map((value, index) => {
                  return <tr
                    key={`refund-${index}`}
                  >
                    <td>{value.order_no}</td>
                    <td>{value.create_at}</td>
                    <td style={{ textAlign: 'center' }}>
                      <div>{value.user.name}</div>
                      <div>{value.user.phone_number}</div>
                    </td>
                    <td style={{ textAlign: 'center' }}>{value.amount}</td>
                    <td>{value.payment_method}</td>
                    <td>{value.remark}</td>
                    <td>{convertRefundStatusToText(value.status)}</td>
                    {
                      isShowAction && <td>
                        <button
                          className="btn btn-outline-primary mb-1 mr-1"
                          onClick={() => onSubmitRefund(value.id)}
                        >
                          {confirmText}
                        </button>
                        <button
                          className="btn btn-outline-primary mb-1"
                          onClick={() => onCancelRefund(value.id)}
                        >
                          ยกเลิก
                        </button>
                      </td>
                    }
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </LoadingMask>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <div></div>
      <div className="mt-2" >
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          breakLabel={<span className="gap">...</span>}
          pageCount={page_count}
          onPageChange={(e) => setcurrent_page(e.selected + 1)}
          forcePage={current_page - 1}
          containerClassName={"pagination"}
          previousLinkClassName={"previous_page"}
          nextLinkClassName={"next_page"}
          disabledClassName={"disabled"}
          activeClassName={"active"}
        />
      </div>
      <div className="mt-2 " style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '5px' }}>
          <span style={{ fontSize: '14px', marginRight: '10px' }}>ค้นหา:</span>
          <input
            autoComplete="off"
            type="number"
            className="form-control- global_filter"
            placeholder="1"
            style={{ width: '80px', paddingLeft: '5px', paddingRight: '5px' }}
            onChange={(e) => {
              setcurrent_page(e.target.value)
            }}
            defaultValue={1}
            min={1}
            max={page_count}
          />
        </div>
        <div style={{ minWidth: '100px' }}>
          <Select
            name="per_page"
            options={limitOptions}
            defaultValue={limit}
            onChange={async (e) => {
              setLimit(e)
            }}
            menuPlacement={'top'}
          />
        </div>
      </div>
    </div>
  </>
}