import { NavLink, useParams } from "react-router-dom";

import React from "react"

export const RiderDetailHeader = (
  { isApproved = true, }
) => {
  const { id } = useParams();

  return <div>
    <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
      <li className="nav-item">
        <NavLink
          className={"nav-link "}
          activeClassName="active"
          to={`/rider/history/${id}`}
        >
          ประวัติพนักงาน
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className={"nav-link "}
          activeClassName="active"
          to={`/rider/vehicle/${id}`}
        >
          ยานพาหนะ
        </NavLink>
      </li>
      {
        isApproved && <>
          <li className="nav-item">
            <NavLink
              className={"nav-link "}
              activeClassName="active"
              to={`/rider/service/${id}`}
            >
              การบริการ
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={"nav-link "}
              activeClassName="active"
              to={`/rider/transaction/${id}`}
            >
              ธุรกรรมทั้งหมด
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={"nav-link "}
              activeClassName="active"
              to={`/rider/affiliate/${id}`}
            >
              Affiliate
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={"nav-link "}
              activeClassName="active"
              to={`/rider/bank/${id}`}
            >
              บัญชีธนาคาร
            </NavLink>
          </li>
        </>
      }
    </ul>
  </div>
}