import React, { useEffect, useState } from "react";
import { api, header_token } from "./config";

import Axios from "axios";
import axios from "axios";
import swal from "sweetalert";
import { useForm } from "react-hook-form";

export const LoginForm = () => {
  const { register, handleSubmit, errors, setValue } = useForm({ defaultValues: { username: '', password: '' } });
  const [isRemember, setIsRemember] = useState(false)

  const login = async (user, pass) => {
    try {
      let res = await axios.post(`${api}/user/login`, {
        username: user,
        password: pass,
        provider: "password",
      });

      if (res.status === 200) {
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("role", "admin");
        // remember me
        if (isRemember) {
          localStorage.setItem("remember_me", JSON.stringify({ user: user, pass: pass, is_remember: isRemember }));
        } else {
          localStorage.setItem("remember_me", JSON.stringify({ user: '', pass: '', is_remember: false }));
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      swal("Login Failed", "Username or Password  wrong", "error");

      return false;
    }
  };

  const unsubscribeTopic = async () => {
    let fcm = await localStorage.getItem("fcm-token");
    // console.log("fcm", fcm);
    if (fcm) {
      try {
        let unsub = await Axios.get(
          `${api}/fcm/unsubscribe/${fcm}`,
          header_token
        );
        // console.log("unsub", unsub);

        if (unsub.status === 200) {
          localStorage.removeItem('fcm-token')

          return true;
        }
      } catch (error) {
        console.log(error);
        swal(`${error.response.data.message}`, {
          icon: "error",
          button: false,
        });
        return false;
      }
    } else {
      localStorage.removeItem('fcm-token')
    }
  };

  const onSubmit = async (value) => {
    let is_login = await login(value.username, value.password);
    if (is_login) {
      window.location = window.location.href;
    }
  };

  const getRememberMe = async () => {
    try {
      const rememberLocal = await localStorage.getItem("remember_me")
      if (rememberLocal === null) {
        setIsRemember(false)
      } else {
        const jsonData = JSON.parse(rememberLocal)
        setIsRemember(jsonData.is_remember)
        if (jsonData.is_remember) {
          setValue('username', jsonData.user)
          setValue('password', jsonData.pass)
        }
      }
    } catch (error) {
      console.log('getRememberMe error: ', error)
    }
  }

  useEffect(() => {
    getRememberMe()
    unsubscribeTopic();
  }, []);

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5 border">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 12,
                    paddingBottom: 32,
                  }}
                >
                  <img
                    src="/images/logo.png"
                    alt="logo"
                    style={{
                      width: 150,
                      borderRadius: "100%",
                    }}
                  />
                </div>
                <h4>Hello! let's get started</h4>
                <h6 className="font-weight-light">Sign in to continue.</h6>
                <form onSubmit={handleSubmit(onSubmit)} className="pt-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className={`form-control form-control-lg ${errors.username ? "error-input" : ""
                        }`}
                      id="username"
                      placeholder="Username"
                      name="username"
                      ref={register({ required: true })}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className={`form-control  form-control-lg${errors.password ? "error-input" : ""
                        }`}
                      id="password"
                      name="password"
                      placeholder="Password"
                      ref={register({ required: true, minLength: 6 })}
                    />
                  </div>
                  <div className="mt-3">
                    <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                      SIGN IN
                    </button>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="form-check" >
                      <input
                        checked={isRemember}
                        type="checkbox"
                        onChange={(v) => setIsRemember(v.target.checked)}
                      /> Keep me signed in
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
