import "react-loadingmask/dist/react-loadingmask.css";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import React, { useEffect, useRef, useState } from "react";
import {
  api,
  header_token,
  img_url,
  rider_status_option,
  selectStyle,
} from "../../config";

import Axios from "axios";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { ExportUserData } from "./ExportUserData";
import { Link } from "react-router-dom";
import LoadingMask from "react-loadingmask";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { UserHead } from "./UserHead";
import { checkImageFlowOld } from '../../utils/Function'
import moment from "moment";
import swal from "sweetalert";

const action_option = [{ value: 0, label: "ลบ" }];

export const UserBusiness = () => {
  const checkAllRef = useRef(null);

  const [loading, setloading] = useState(false);

  const [export_loading, setexport_loading] = useState(false);
  const [export_data, setexport_data] = useState([]);
  const [trigger, settrigger] = useState(false);
  const [data, setdata] = useState([]);
  const [bank_option, setbank_option] = useState([]);

  const [img, setimg] = useState("");
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  // deposit credit
  const [add_name, setadd_name] = useState("");
  const [bank, setbank] = useState("");
  const [rider_id, setrider_id] = useState("");
  const [credit_amount, setcredit_amount] = useState(1);
  const [note, setnote] = useState("");

  const [filter_name, setfilter_name] = useState("");
  const [filter_status, setfilter_status] = useState({
    value: "",
    label: "เลือก..",
  });
  const [action, setaction] = useState({ value: "", label: "เลือก.." });

  const getData = async () => {
    setloading(true);
    try {
      let send_data = {
        // area_id: filter_area.value,
        // employee_type_id: filter_type.value,
        search: filter_name,
        status: filter_status.value === "" ? -1 : parseInt(filter_status.value),
        // team_id: filter_team.value,
        role: ["user_business"],
        status: -1
      };

      let { data } = await Axios.post(
        `${api}/admin/user/filter?page=${current_page}&limit=${limit}`,
        send_data,
        header_token
      );
      // console.log("data", data);

      let cal_page_count = Math.ceil(data.meta.total_count / limit);
      setpage_count(cal_page_count);
      settotal_count(data.meta.total_count);

      setdata(data.users);
    } catch (error) {
      // console.log(error.response);
    }
    setloading(false);
  };

  const deleteUser = async (user_id) => {
    try {
      let res = await Axios.delete(
        `${api}/admin/user/profile/${user_id}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // console.log(error.response);
      return false;
    }
  };

  const exportexcel = async () => {
    setexport_loading(true);
    try {
      let send_data = {
        search: filter_name,
        status: filter_status.value === "" ? -1 : filter_status.value,
        // team_id: filter_team.value,
        role: ["user_business"],
        status: -1,
      };

      let { data } = await Axios.post(
        `${api}/admin/user/filter?page=${1}&limit=${9999999999}`,
        send_data,
        header_token
      );
      console.log("data", data);

      let _export = [];
      if (data.users) {
        data.users.forEach((value, index) => {
          _export.push({
            name: value.full_name,
            created_at: moment(value.created_at).format("DD/MM/YYYY"),
            birth_date: moment(value.birth_date).format("DD/MM/YYYY"),
            address: value.location.address,
            sub_district: value.location.sub_district.name_th,
            district: value.location.district.name_th,
            province: value.location.province.name_th,
            zip_code: value.location.sub_district.zip_code,
            email: value.email,
            phone_number: value.phone_number,
            point: value.point,
            company_code: value.company_code,
            promo_code: value.promo_code,
            wallet: value.credit_bonus,
            credit: value.credit,
          });
        });
      }

      setexport_data(_export);
    } catch (error) {
      // console.log(error);
      // console.log(error.response);
    }
    setexport_loading(false);
  };

  const addCredit = async (user_id) => {
    if (credit_amount && bank) {
      try {
        let send_data = {
          amount: credit_amount,
          bank_id: bank.value,
          picture: "",
          remark: note,
          transaction_date: `${moment().format("YYYY-MM-DD")} ${moment().format(
            "LTS"
          )}`,
        };

        // console.log("send_data", send_data);
        let res = await Axios.post(
          `${api}/admin/wallet/deposit/${user_id}?status=1`,
          send_data,
          header_token
        );

        // console.log(res);
        if (res.status === 201) {
          swal("เติมเครดิตเสร็จสิ้น !!", {
            icon: "success",
          });
          getData();
          setcredit_amount(1);
          setnote("");
          setbank("");
          return true;
        } else {
          return false;
        }
      } catch (error) {
        swal(`${error.response.data.message}`, {
          icon: "error",
          button: false,
        });
        return false;
      }
    } else {
      alert("ข้อมูลไม่ครบ");
    }
  };

  const getBank = async () => {
    try {
      let { data } = await Axios.get(`${api}/bank`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setbank_option(tmp);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getData();
    getBank();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getData();
  }, [filter_name, filter_status]);

  useEffect(() => {
    getData();
  }, [current_page]);

  return (
    <div>
      <UserHead />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">
                    ทั้งหมด {total_count.toLocaleString()} รายการ
                  </h4>
                </div>

                <div className="form-row align-items-center mb-2">
                  <div className="col mr-2">
                    <span className="text-muted tx-13">ค้นหา</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      id="global_filter"
                      placeholder="ชื่อ / เบอร์โทร"
                      value={filter_name}
                      onChange={(e) => {
                        setfilter_name(e.target.value);
                      }}
                    />
                  </div>

                  <div className="col">
                    <div className="row">
                      <div style={{ width: 120 }}>
                        <span className="text-muted tx-13">สถานะ</span>
                        <Select
                          styles={selectStyle}
                          value={filter_status}
                          onChange={(e) => {
                            setfilter_status(e);
                          }}
                          options={rider_status_option}
                          placeholder={"ประเภท.."}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="row">
                      <div style={{ width: 120 }}>
                        <span className="text-muted tx-13">ดำเนินการ</span>
                        <Select
                          styles={selectStyle}
                          value={action}
                          onChange={(e) => {
                            setaction(e);
                          }}
                          options={action_option}
                          placeholder={"ดำเนินการ.."}
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-primary mr-2"
                    style={{ marginTop: 23 }}
                    onClick={async () => {
                      let count = data.filter((e) => e.checked === true);
                      if (action.value === 0 && count.length > 0) {
                        swal({
                          title: `ต้องการลบสมาชิกที่เลือกหรือไม่ ?`,
                          confirmButtonText: "ลบ",
                          cancelButtonText: "ยกเลิก",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then(async (willDelete) => {
                          if (willDelete) {
                            let sending = [];
                            data.forEach((value) => {
                              if (value.checked === true) {
                                sending.push(deleteUser(value.id));
                              }
                            });

                            let is_success = true;
                            await Promise.all(sending).then((res) => {
                              // console.log(res);
                              if (
                                res.filter((e) => e === true).length === 0 ||
                                res.length === 0
                              )
                                is_success = false;
                            });

                            if (is_success) {
                              getData();
                              swal("ลบเสร็จสิ้น !!", {
                                icon: "success",
                              });
                            } else {
                              swal("เกิดข้อผิดพลาด !!", {
                                icon: "error",
                              });
                            }
                          }
                        });
                      } else {
                        alert("เลือกตัวดำเนินการ");
                      }
                    }}
                  >
                    ดำเนินการ
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary mr-2"
                    style={{ marginTop: 23 }}
                    onClick={async () => {
                      if (!trigger && !export_loading) {
                        await exportexcel();
                        await settrigger(true);
                      }
                    }}
                  >
                    {export_loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                    {` `}Export
                  </button>
                  <ExportUserData
                    data={export_data}
                    trigger={trigger}
                    settrigger={settrigger}
                    isUserBusiness={true}
                  />
                </div>
              </div>

              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="sticky-div">
                    <table id="dataTable" className="table sticky-table">
                      <thead>
                        <tr>
                          <th style={{ width: 30 }} className="sticky-head">
                            <input
                              type="checkbox"
                              className="selectAll "
                              name="selectAll"
                              defaultChecked={false}
                              ref={checkAllRef}
                              onClick={(e) => {
                                // console.log(checkAllRef.current.checked);
                                let tmp = [...data];
                                let _tmp = [];
                                tmp.forEach((value, idx) => {
                                  value.checked = checkAllRef.current.checked;
                                  _tmp.push(value);
                                });
                                setdata(_tmp);
                                // console.log(data);
                              }}
                            />
                          </th>
                          <th style={{ width: 100 }} className="sticky-head">
                            รูป
                          </th>
                          <th style={{ width: 80 }} className="sticky-head">
                            รหัส
                          </th>
                          <th style={{ width: 250 }} className="sticky-head">
                            ชื่อ-นามสกุล
                          </th>
                          {/* <th style={{ width: 150 }}>วันเดือนปีเกิด</th> */}
                          <th style={{ width: 150 }}>Company Code</th>
                          <th style={{ width: 150 }}>Promo Code</th>
                          {/* <th style={{ width: 300 }}>ที่อยู่จัดส่ง</th>
                          <th style={{ width: 100 }}>ตำบล/แขวง</th>
                          <th style={{ width: 100 }}>อำเภอ/เขต</th>
                          <th style={{ width: 100 }}>จังหวัด</th>
                          <th style={{ width: 100 }}>รหัสไปรษณีย์</th> */}
                          <th style={{ width: 200 }}>อีเมล</th>
                          <th style={{ width: 200 }}>เบอร์โทร</th>
                          {/* <th style={{ width: 120 }}>ประวัติการธุรกรรม</th>
                          <th style={{ width: 120 }}>ประวัติการใช้งาน</th> */}
                          <th style={{ width: 80 }}>วอลเล็ต</th>
                          <th style={{ width: 80 }}>เครดิต</th>
                          <th style={{ width: 80 }}>สถานะ</th>
                          <th style={{ width: 200 }}>ดำเนินการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          const isShowOldFlow = checkImageFlowOld(value.picture)

                          return (
                            <tr
                              key={index}
                              style={{
                                backgroundColor: value.checked ? "#e8eaed" : "",
                              }}
                            >
                              <td
                                style={{
                                  backgroundColor: value.checked
                                    ? "#e8eaed "
                                    : "",
                                }}
                                className="sticky-col"
                              >
                                <input
                                  type="checkbox"
                                  checked={value.checked || ""}
                                  onChange={(e) => {
                                    let tmp = [...data];
                                    data.forEach((vl, idx) => {
                                      if (vl.id === value.id) {
                                        tmp[idx].checked = !tmp[idx].checked;
                                        setdata(tmp);
                                      }
                                    });
                                  }}
                                />
                              </td>
                              <td
                                style={{
                                  backgroundColor: value.checked
                                    ? "#e8eaed "
                                    : "",
                                }}
                                className="sticky-col"
                              >
                                <div
                                  className="thumbnail cursor-img"
                                  data-toggle="modal"
                                  data-target=".show-img"
                                  onClick={() => {
                                    setimg(
                                      value.picture === ""
                                        ? "/images/no-img.jpg"
                                        : isShowOldFlow ? `${img_url}${value.picture}` : value.picture
                                    );
                                  }}
                                >
                                  <img
                                    className="lazy img-responsive"
                                    src={
                                      value.picture === ""
                                        ? "/images/no-img.jpg"
                                        : isShowOldFlow ? `${img_url}${value.picture}` : value.picture
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = "/images/no-img.jpg";
                                    }}
                                  />
                                </div>
                              </td>
                              <td
                                style={{
                                  backgroundColor: value.checked
                                    ? "#e8eaed "
                                    : "",
                                }}
                                className="sticky-col"
                              >
                                {value.user_id}
                              </td>
                              <td
                                style={{
                                  backgroundColor: value.checked
                                    ? "#e8eaed "
                                    : "",
                                }}
                                className="sticky-col"
                              >
                                <Link to={`/user/detail/${value.id}`}>
                                  <span>{value.full_name}</span>
                                </Link>
                              </td>
                              {/* <td>
                                {value.birth_date !== '' ? moment(value.birth_date).format("DD/MM/YYYY") : '-'}
                              </td> */}
                              <td>{value.company_code || '-'}</td>
                              <td>{value.promo_code || '-'}</td>
                              {/* <td>{value.location.address}</td>
                              <td>{value.location.sub_district.name_th}</td>
                              <td>{value.location.district.name_th}</td>
                              <td>{value.location.province.name_th}</td>
                              <td>{value.location.sub_district.zip_code}</td> */}
                              <td>{value.email}</td>
                              <td>{value.phone_number}</td>
                              {/* <td>
                                <Link to={`/user/wallet/${value.id}`}>
                                  <button className="btn btn-outline-primary btn-sm mb-1">
                                    ประวัติ
                                  </button>
                                </Link>
                              </td>
                              <td>
                                <Link to={`/user/order/${value.id}`}>
                                  <button className="btn btn-outline-primary btn-sm mb-1">
                                    ประวัติ
                                  </button>
                                </Link>
                              </td> */}
                              <td>{value.credit}</td>
                              <td>{value.credit_bonus}</td>
                              <td>
                                {(() => {
                                  switch (value.status) {
                                    case 0:
                                      return (
                                        <div className="badge badge-warning">
                                          ระงับ
                                        </div>
                                      );
                                    // break;
                                    case 1:
                                      return (
                                        <div className="badge badge-success">
                                          ใช้งาน
                                        </div>
                                      );

                                    // break;
                                    case 2:
                                      return (
                                        <div className="badge badge-danger">
                                          ไม่อนุมัติ
                                        </div>
                                      );
                                    // break;
                                    case 3:
                                      return (
                                        <div className="badge badge-primary">
                                          รออนุมัติ
                                        </div>
                                      );
                                    // break;

                                    default:
                                      break;
                                  }
                                })()}
                              </td>
                              <td>
                                <Link to={`/user/edit/${value.id}`}>
                                  <button className="btn btn-outline-primary btn-sm mb-1 mr-1">
                                    แก้ไข
                                  </button>
                                </Link>
                                <Link to={`/user/wallet/${value.id}`}>
                                  <button className="btn btn-outline-primary btn-sm mb-1 mr-1">
                                    ประวัติธุรกรรม
                                  </button>
                                </Link>
                                <Link to={`/user/order/${value.id}`}>
                                  <button className="btn btn-outline-primary btn-sm mb-1 mr-1">
                                    ประวัติการใช้งาน
                                  </button>
                                </Link>
                                <button
                                  data-toggle="modal"
                                  data-target={`.credit-add`}
                                  className="btn btn-outline-primary btn-sm mb-1"
                                  onClick={() => {
                                    setrider_id(value.id);
                                    setadd_name(value.full_name);
                                    setcredit_amount(1);
                                    setnote("");
                                    setbank("");
                                  }}
                                >
                                  เติมเครดิต
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <div></div>
                    <div className="mt-2">
                      <ReactPaginate
                        previousLabel={"← Previous"}
                        nextLabel={"Next →"}
                        breakLabel={<span className="gap">...</span>}
                        pageCount={page_count}
                        onPageChange={(e) => setcurrent_page(e.selected + 1)}
                        forcePage={current_page - 1}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previous_page"}
                        nextLinkClassName={"next_page"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                      />
                    </div>
                    <div className="mt-2 " style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <span style={{ fontSize: '14px', marginRight: '10px' }}>ค้นหา:</span>
                      <input
                        autoComplete="off"
                        type="number"
                        className="form-control- global_filter"
                        placeholder="1"
                        style={{ width: '80px', paddingLeft: '5px', paddingRight: '5px' }}
                        onChange={(e) => {
                          setcurrent_page(e.target.value)
                        }}
                        defaultValue={1}
                        min={1}
                        max={page_count}
                      />
                    </div>
                  </div>
                </div>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div >
      <div id="showModdal" className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/images/no-img.jpg";
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div id="showModdal" className={`modal  credit-add`} role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>เติมเครดิต</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="add_name">พนักงาน</label>
                </div>
                <div className="col-lg-5">
                  <input
                    id="add_name"
                    className="form-control"
                    name="add_name"
                    type="text"
                    value={add_name}
                    onChange={(e) => setadd_name(e.target.value)}
                    readOnly
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="bank">ธนาคาร</label>
                </div>
                <div className="col-lg-5">
                  <Select
                    value={bank}
                    onChange={(e) => {
                      setbank(e);
                    }}
                    options={bank_option}
                    placeholder={"ธนาคาร.."}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="credit_amount">จำนวนเงิน(บาท)</label>
                </div>
                <div className="col-lg-5">
                  <input
                    id="credit_amount"
                    className="form-control"
                    name="credit_amount"
                    type="number"
                    placeholder="บาท"
                    onChange={(e) => {
                      if (e.target.value > 0) {
                        setcredit_amount(parseInt(e.target.value));
                      }
                    }}
                    value={credit_amount}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="name">วันที่</label>
                </div>
                <div className="col-lg-5">
                  <div className="input-group input-daterange d-flex align-items-center">
                    <DayPickerInput
                      value={new Date()}
                      placeholder={"วัน/เดือน/ปี"}
                      style={{ width: "100%" }}
                      inputProps={{
                        style: {
                          width: "100%",
                          padding: 5,
                        },
                        disabled: true,
                      }}
                      format="L"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      dayPickerProps={{
                        locale: "th",
                        localeUtils: MomentLocaleUtils,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="name">หมายเหตุ</label>
                </div>
                <div className="col-lg-5">
                  <input
                    id="name"
                    className="form-control"
                    name="name"
                    type="text"
                    value={note}
                    onChange={(e) => setnote(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mr-2 mb-4">
                <div className="col-12">
                  <div className="row">
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-outline-primary mr-2 ml-auto "
                    >
                      ยกเลิก
                    </button>
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-primary  mr-2 "
                      onClick={() => {
                        addCredit(rider_id);
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
