import "react-loadingmask/dist/react-loadingmask.css";
import "react-day-picker/lib/style.css";
import "moment/locale/th";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import { api, header_token } from "../../config";
import { useEffect, useState } from "react";

import { AffiliateTable } from './Table'
import Axios from "axios";
import DayPickerInput from "react-day-picker/DayPickerInput";
import LoadingMask from "react-loadingmask";
import { MemberTable } from "./MemberTable"
import { RiderDetailHead } from "../Rider/RiderDetailHead";
import { RiderDetailHeader } from "../Rider/RiderDetailHeader";
import { UserDetailHeader } from "../User/UserDetailHeader";
import moment from "moment";
import swal from "sweetalert";
import { useParams } from "react-router-dom";

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -109 }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

export const AffiliateDetail = () => {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const [loadingHistory, setloadingHistory] = useState(false);
  const [loadingMember, setLoadingMember] = useState(false);
  const [history, setHistory] = useState([]);
  const [detail, setDetail] = useState(null)
  const [indexTab, setIndexTab] = useState(0)
  const [role, setRole] = useState('rider')
  const [members, setMembers] = useState([])
  // filter
  const [filterFrom, setFilterFrom] = useState('');
  const [filterTo, setFilterTo] = useState('');

  const [img, setimg] = useState("");
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit, setLimit] = useState({ label: "50", value: 50, });
  const [total_count, settotal_count] = useState(0);

  const [currentPageMember, setcurrentPageMember] = useState(1);
  const [pageCountMember, setPageCountMember] = useState(0);
  const [limitMember, setLimitMember] = useState({ label: "50", value: 50, });
  const [totalCountMember, setธotalCountMember] = useState(0);

  const getAffiliateHistory = async () => {
    setloadingHistory(true);
    try {
      let start = filterFrom !== '' ? moment(filterFrom, "DD/MM/YYYY").format("YYYY-MM-DD") : '';
      let end = filterTo !== '' ? moment(filterTo, "DD/MM/YYYY").format("YYYY-MM-DD") : '';

      const body = {
        "start_date": start,
        "end_date": end,
      }

      let { data } = await Axios.post(
        `${api}/affiliate/history/${id}?page=${current_page}&limit=${limit.value}`,
        body,
        header_token
      );
      settotal_count(data.pagination.total_count)
      setHistory(data.order)

      let cal_page_count = Math.ceil(data.pagination.total_count / limit.value);

      setpage_count(cal_page_count);
    } catch (error) {
      console.log('error: ', error)
      setHistory([])
      settotal_count(0)
    }
    setloadingHistory(false);
  }

  const getAffiliateDetail = async (affId) => {
    try {
      let { data } = await Axios.get(
        `${api}/affiliate/${affId}`,
        header_token
      );
      setDetail(data)
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const getAffiliateMember = async () => {
    setLoadingMember(true)
    try {
      let { data } = await Axios.get(
        `${api}/admin/affiliate/members/${id}?page=${currentPageMember}&limit=${limitMember.value}`,
        header_token
      );
      setMembers(data.user)
      setธotalCountMember(data.pagination.total_count)
      let cal_page_count = Math.ceil(data.pagination.total_count / limitMember.value);

      setPageCountMember(cal_page_count);
    } catch (error) {
      setMembers([])
      setธotalCountMember(0)
      setPageCountMember(0)
    }
    setLoadingMember(false)
  }

  const getAffiliateId = async () => {
    try {
      setloading(true);
      let { data } = await Axios.get(
        `${api}/admin/user/profile/${id}`,
        header_token
      );
      console.log('data: ', data)
      if (data.affiliate !== null && data.affiliate !== undefined) {
        await getAffiliateDetail(data.affiliate.id)
      }
      setRole(data.role.name_en ?? '')
      setloading(false);
    } catch (error) {
      setloading(false);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  useEffect(() => {
    getAffiliateId()
    getAffiliateHistory()
    getAffiliateMember()
  }, [])

  useEffect(() => {
    getAffiliateHistory()
  }, [filterFrom, filterTo, limit, current_page]);

  useEffect(() => {
    getAffiliateMember()
  }, [limitMember, currentPageMember]);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">ประวัติพนักงาน</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                พนักงานส่งทั้งหมด
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {
                role === 'rider' && <>
                  <RiderDetailHead />
                  <RiderDetailHeader />
                </>
              }

              {
                role === 'user' && <>
                  <UserDetailHeader />
                </>
              }

              <ul
                className="nav nav-pills"
                id="pills-tab"
                role="tablist"
                style={{ marginTop: '10px' }}
              >
                <li className="nav-item mb-1">
                  <div
                    className={`nav-link ${indexTab === 0 ? 'active' : ''}`}
                    onClick={() => setIndexTab(0)}
                    style={{ cursor: 'pointer' }}
                  >
                    รายละเอียด
                  </div>
                </li>
                <li className="nav-item">
                  <div
                    className={`nav-link ${indexTab === 1 ? 'active' : ''}`}
                    onClick={() => setIndexTab(1)}
                    style={{ cursor: 'pointer' }}
                  >
                    ประวัติ
                  </div>
                </li>
              </ul>
              {
                indexTab === 0
                  ? detail !== null ? <div>
                    <LoadingMask loading={loading} text={"loading..."}>
                      <div style={{ minHeight: 500 }}>
                        <div className="tab-content" id="myTabContent">
                          <table
                            style={{ width: "100%", tableLayout: "fixed" }}
                            border="0"
                          >
                            <tbody>
                              <div>
                                <b>ข้อมูลทั่วไป</b>
                              </div>
                              <tr>
                                <td width="20%">วันที่เปิดใช้งาน</td>
                                <td width="30%">{moment(detail.created_at).format("DD/MM/YYYY HH:mm")}</td>
                                <td width="20%">รหัสแนะนำ</td>
                                <td width="30%">{detail.code}</td>
                              </tr>
                              <tr>
                                <td width="20%">ออเดอร์สะสม</td>
                                <td width="30%">{detail.order_count}</td>
                                <td width="20%">ออเดอร์ปัจจุบัน</td>
                                <td width="30%">{detail.order_count}</td>
                              </tr>
                              <tr>
                                <td width="20%">จำนวนรายได้สะสม</td>
                                <td width="30%">{detail.total}</td>
                                <td width="20%">จำนวนรายได้ปัจจุบัน</td>
                                <td width="30%">{detail.summary_total}</td>
                              </tr>
                              <br />
                            </tbody>
                          </table>
                          <div>
                            <b>ผู้ใช้งานที่ถูกแนะนำ</b>
                          </div>
                          <br />
                          {/* members */}
                          <div className="col">
                            <h4 className="mb-4 mt-2">ทั้งหมด {totalCountMember} รายการ</h4>
                          </div>
                          <MemberTable
                            members={members}
                            loading={loadingMember}
                            setLimit={setLimitMember}
                            limit={limitMember}
                            page_count={pageCountMember}
                            setcurrent_page={setcurrentPageMember}
                            current_page={currentPageMember}
                          />
                        </div>
                      </div>
                    </LoadingMask>
                  </div>
                    : <div style={{ minHeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      ยังไม่มีการเปิดใช้งาน
                    </div>
                  : <div>
                    <div className="row" style={{ marginRight: '10px', alignItems: 'center', justifyContent: 'end', marginBottom: '10px' }}>
                      <div className="col">
                        <h4 className="mb-4 mt-2">ทั้งหมด {total_count} รายการ</h4>
                      </div>
                      <div>
                        <DayPickerInput
                          value={filterFrom}
                          onDayChange={(e) => {
                            setFilterFrom(moment(e, "DD/MM/YYYY").format("DD/MM/YYYY"));
                          }}
                          overlayComponent={CustomOverlay}
                          style={{ width: "100%" }}
                          inputProps={{
                            style: {
                              width: "100%",
                            },
                          }}
                          placeholder={"วัน/เดือน/ปี"}
                          format="L"
                          formatDate={formatDate}
                          parseDate={parseDate}
                          dayPickerProps={{
                            locale: "th",
                            localeUtils: MomentLocaleUtils,
                          }}
                        />
                      </div>
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>ถึง</span>
                      <div>
                        <DayPickerInput
                          value={filterTo}
                          onDayChange={(e) => {
                            setFilterTo(moment(e, "DD/MM/YYYY").format("DD/MM/YYYY"));
                          }}
                          overlayComponent={CustomOverlay}
                          style={{ width: "100%" }}
                          inputProps={{
                            style: {
                              width: "100%",
                            },
                          }}
                          placeholder={"วัน/เดือน/ปี"}
                          format="L"
                          formatDate={formatDate}
                          parseDate={parseDate}
                          dayPickerProps={{
                            locale: "th",
                            localeUtils: MomentLocaleUtils,
                          }}
                        />
                      </div>
                    </div>
                    <AffiliateTable
                      loading={loadingHistory}
                      history={history}
                      setLimit={setLimit}
                      limit={limit}
                      page_count={page_count}
                      setcurrent_page={setcurrent_page}
                      current_page={current_page}
                    />
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}