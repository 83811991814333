import { NavLink, Route, Switch } from "react-router-dom";

import React from "react";
import { ReportPaySolution } from './ReportPaySolution'
import { ReportUser } from './ReportUser'

export const ReportHead = () => {
  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">รายงาน</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/report/user"
              >
                ผู้ใช้งานในระบบ
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/report/pay-solution"
              >
                Pay Solution
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <Switch>
        <Route exact path={`/report/user`}>
          <ReportUser />
        </Route>

        <Route exact path={`/report/pay-solution`}>
          <ReportPaySolution />
        </Route>
      </Switch>
    </div>
  )
}