import { Link } from "react-router-dom";
import LoadingMask from "react-loadingmask";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import moment from "moment";

const limitOptions = [
  { label: "50", value: 50, },
  { label: "100", value: 100, },
  { label: "150", value: 150, },
]

export const MemberTable = (props) => {
  const {
    members = [],
    loading = false,
    setLimit = () => { },
    limit = { label: "50", value: 50 },
    page_count = 0,
    setcurrent_page = () => { },
    current_page = 1,
  } = props

  return <>
    <LoadingMask loading={loading} text={"loading..."}>
      <div style={{ minHeight: 500 }}>
        <div className="table-responsive">
          <table id="dataTable" className="table ">
            <thead>
              <tr>
                <th style={{ minWidth: 200 }}>วันที่</th>
                <th style={{ minWidth: 200 }}>รหัส</th>
                <th style={{ minWidth: 200 }}>ชื่อ</th>
              </tr>
            </thead>
            <tbody>
              {
                members.map((value, index) => {
                  return <tr key={`affiliate-members-${index}`}>
                    <td>{moment(value.created_at).format('DD-MM-YYYY HH:mm')}</td>
                    <td>{value.user_id}</td>
                    <td>
                      <Link to={`/user/detail/${value._id}`} target="_blank" rel="noopener noreferrer">
                        {value.full_name}
                      </Link>
                    </td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </LoadingMask >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <div></div>
      <div className="mt-2" >
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          breakLabel={<span className="gap">...</span>}
          pageCount={page_count}
          onPageChange={(e) => setcurrent_page(e.selected + 1)}
          forcePage={current_page - 1}
          containerClassName={"pagination"}
          previousLinkClassName={"previous_page"}
          nextLinkClassName={"next_page"}
          disabledClassName={"disabled"}
          activeClassName={"active"}
        />
      </div>
      <div className="mt-2 " style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ minWidth: '100px' }}>
          <Select
            name="per_page"
            options={limitOptions}
            defaultValue={limit}
            onChange={async (e) => {
              setLimit(e)
            }}
            menuPlacement={'top'}
          />
        </div>
      </div>
    </div>
  </>
}