import "react-loadingmask/dist/react-loadingmask.css";
import "react-day-picker/lib/style.css";
import "moment/locale/th";
import "react-loadingmask/dist/react-loadingmask.css";

import { CustomOverlay, convertRefundMethodToText } from "../../utils/Function";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import React, { useEffect, useState } from "react";
import { api, header_token } from "../../config";

import Axios from "axios";
import DayPickerInput from "react-day-picker/DayPickerInput";
import LoadingMask from "react-loadingmask";
import ReactPaginate from "react-paginate";
import { RefundTable } from "./RefundTable";
import Select from "react-select";
import moment from "moment";
import swal from "sweetalert";

export const RefundHistory = () => {
  // filter
  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [filterName, setFilterName] = useState("");
  const [filterType, setFilterType] = useState({ value: -1, label: "ทั้งหมด" });
  const [typeOptions, setTypeOptions] = useState([
    { value: -1, label: "ทั้งหมด" },
    { value: 'full', label: "เครดิตการ์ด/เดบิตการ์ด" },
    { value: 'promptpay', label: "PromptPay" },
    { value: 'wallet', label: "wallet" },
  ]);
  const [data, setData] = useState([])

  const [filterStatus, setFilterStatus] = useState(
    { value: -1, label: "ทั้งหมด" },
  );

  const [statusOptions, setStatusOptions] = useState([
    { value: -1, label: "ทั้งหมด" },
    { value: 0, label: "ไม่สำเร็จ" },
    { value: 1, label: "รอดำเนินการ" },
    { value: 2, label: "กำลังดำเนินการ" },
    { value: 3, label: "สำเร็จ" },
  ]);

  // table
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit, setLimit] = useState({ label: "50", value: 50, });
  const [total_count, settotal_count] = useState(0);
  const [loading, setLoading] = useState(false)

  const getRefundTransaction = async () => {
    setLoading(true)
    try {
      let start = "";
      let end = "";

      let send_data = {
        order_no: filterName,
      };
      if (filterStatus.value > -1)
        send_data.status = filterStatus.value

      if (filterType.value !== -1)
        send_data.type = filterType.value

      if (filterFrom || filterTo) {
        start = moment(filterFrom, "DD/MM/YYYY").format("YYYY-MM-DD");
        end = moment(filterTo, "DD/MM/YYYY").format("YYYY-MM-DD");
        send_data.start_date = start
        send_data.end_date = end
      }

      const { data: { refunds, pagination } } = await Axios.post(
        `${api}/admin/refund?page=${current_page}&limit=${limit.value}`,
        send_data,
        header_token,
      );

      const listRefund = []

      for (let index = 0; index < refunds.length; index++) {
        const _refund = refunds[index];
        listRefund.push({
          id: _refund.id,
          order_id: _refund.order_id,
          order_no: _refund.order_no,
          create_at: moment(_refund.created_at).format("DD/MM/YYYY LT"),
          user: {
            id: _refund.user_id,
            name: _refund.full_name,
            phone_number: _refund.phone_number
          },
          amount: _refund.total.toLocaleString(),
          payment_method: convertRefundMethodToText(_refund.type),
          remark: _refund.remark,
          status: _refund.status,
        })
      }

      setData(listRefund)
      settotal_count(pagination.total_count)

    } catch (error) {
      console.log('getRefundTransaction: ', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getRefundTransaction();
  }, [filterFrom, filterTo, current_page, filterName, filterStatus, filterType]);

  return (
    <div>
      <div className="row mb-2">
        <span className="ml-2 mt-auto mb-auto h4 ">
          {" "}
          {moment().format("LL")}
        </span>
        <div className="ml-auto mr-2">
          <p className="tx-13 text-muted mb-2">ค้นหาจากช่วงเวลา</p>
          <div className="InputFromTo">
            <DayPickerInput
              value={filterFrom}
              onDayChange={(e) => {
                setFilterFrom(e);
                if (!filterTo) {
                  setFilterTo(e);
                }
              }}
              placeholder={"วัน/เดือน/ปี"}
              format="L"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                locale: "th",
                localeUtils: MomentLocaleUtils,
              }}
            />{" "}
            ถึง{" "}
            <span className="InputFromTo-to">
              <DayPickerInput
                value={filterTo}
                onDayChange={(e) => {
                  setFilterTo(e);
                  if (!filterFrom) {
                    setFilterFrom(e);
                  }
                }}
                overlayComponent={CustomOverlay}
                placeholder={"วัน/เดือน/ปี"}
                format="L"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  locale: "th",
                  localeUtils: MomentLocaleUtils,
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">ทั้งหมด {total_count} รายการ</h4>
                </div>
                <div className="form-row align-items-center mb-2  ">
                  <div className=" mr-2" style={{ width: 200 }}>
                    <span className="tx-13 text-muted">ค้นหา</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      id="global_filter"
                      placeholder="ชื่อผู้ใช้งาน / Ref ID"
                      value={filterName}
                      onChange={(e) => {
                        setFilterName(e.target.value);
                      }}
                    />
                  </div>

                  <div className="col ml-1 mr-3">
                    <div className="row">
                      <div style={{ width: 200 }}>
                        <span className="tx-13 text-muted">ประเภท</span>
                        <Select
                          value={filterType}
                          onChange={(e) => {
                            setFilterType(e);
                          }}
                          options={typeOptions}
                          placeholder={"ประเภท"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col ml-1 mr-2">
                    <div className="row">
                      <div style={{ width: 200 }}>
                        <span className="tx-13 text-muted">สถานะ</span>
                        <Select
                          value={filterStatus}
                          onChange={(e) => {
                            setFilterStatus(e);
                          }}
                          options={statusOptions}
                          placeholder={"สถานะ"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <RefundTable
                loading={loading}
                data={data}
                setLimit={setLimit}
                limit={limit}
                page_count={page_count}
                setcurrent_page={setcurrent_page}
                current_page={current_page}
                isShowAction={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}